/* @import url('https://fonts.googleapis.com/css2?family=Exo:wght@500;600&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap'); */

@font-face {
  font-family: 'SFPro-Regular';
  /* src: url('/fonts/SFPro-Regular.woff2') format('woff'),
    url('/fonts/SFPro-Regular.woff2') format('woff2'); */
  src: url('/fonts/SFProDisplay-Regular.woff2') format('woff'),
    url('/fonts/SFProDisplay-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFPromd';
  src: url('/fonts/SFProDisplay-Semibold.woff2') format('woff2'),
    url('/fonts/SFProDisplay-Semibold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Integral CF Bold';
  src: url('/fonts/IntegralCF-Bold.woff2') format('woff2'),
    url('/fonts/IntegralCF-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Integral CF';
  src: url('/fonts/IntegralCF-Regular.woff2') format('woff2'),
    url('/fonts/IntegralCF-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'SFPro';
  src: url('/fonts/SFProDisplay-Heavy.woff2') format('woff2'),
    url('/fonts/SFProDisplay-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

:root {
  --common-font: 'SFPro-Regular';
  --common-fontbd: 'SFPro';
  --common-fontmd: 'SFPromd';
  --inte-BD: 'Integral CF Bold';
  --inte: 'Integral CF';
  --green: #5fb5be;
  --exo: 'Exo', sans-serif;
  --site-purple: #621D87;
}


html {
  overflow-x: hidden;
}

* {
  font-family: var(--common-font);
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
}

button,
input,
select,
textarea {
  font-family: var(--common-font);
}

.header-main {
  text-align: center;
  padding: 17px 0;
}

.home-crousal img {
  max-width: 300px;
  margin: 26px 0;
}

/* ul.control-dots {
  bottom: -9px !important;
} */

ul.thumbs.animated {
  justify-content: center;
  display: flex;
}

ul.thumbs.animated li {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ul.thumbs.animated li img {
  max-width: 50px !important;
} */

.locat_test {
  position: absolute;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 1px #ddd;
  top: 23px;
  min-width: 100px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 0;
  display: none;
}

.howar:hover .locat_test {
  display: block;
}

.howar {
  position: relative;
}

.locat_test li a {
  color: #000;
  font-size: 16px;
}

.howar span {
  color: #fff;
  cursor: pointer;
}

.locals {
  float: right;
}

/* 
ul.navbar-nav.mr-auto li a input {
  background: #fff !important;
  border: 0 !important;
  border-radius: 4px !important;
  margin: 0 3px !important;
}

.locat_test li {
  width: 100%;
  text-align: left;
}

.locat_test li a {
  margin: 0 !important;
}

ul.navbar-nav.mr-auto {
  width: 100% !important;
  text-align: right !important;
  float: right !important;
  margin-left: auto !important;
  justify-content: flex-end !important;
} */

.logo-img {
  margin-right: 20px;
}


.footer-second {
  text-align: center;
  padding: 20px 0;
}

/* footer-second i {
  margin: 0 4px;
} */

.product_cat h3 {
  width: 100%;
  float: left;
  text-align: center;
  padding: 12px 0 40px;
  color: #000;
}

.product_cat .img img {
  height: auto;
}

.product_cat .img {
  min-height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.product_cat .categories a {
  color: #000;
  font-size: 18px;
  text-transform: capitalize;
}

.product_cat h4 {
  width: 100%;
  float: left;
  text-align: center;
  padding: 12px 0 40px;
  color: #000;
}

.product_cat {
  padding: 100px 0 80px;
  margin: 0;
}

p {
  font-size: 18px;
  line-height: 30px;
}

.product_cat h2 {
  margin-bottom: 27px;
}

body h4 {
  font-size: 50px;
  font-family: var(--common-fontmd);
  text-align: center;
}

.product_cat .img img {
  position: relative;
  left: 110px;
  top: -70px;
}

.product_cat .categories {
  text-align: center;
  position: relative;
  left: 110px;
  top: -70px;
}

.product_cat .categories a {
  color: var(--green);
  font-size: 33px;
}

.custom-container {
  padding: 0 110px;
  width: 100%;
}

.home-crousal img {
  width: 100%;
  max-width: 100%;
}

.banner-sec {
  padding: 310px 0;
  background-size: 100% 100%;
  background-position: top 60px center;
}

.banner-sec .row {
  align-items: center;
}

.banner-left-content h4 {
  color: #fff;
  text-transform: uppercase;
  font-size: 80px;
  font-weight: 600;
}

body .navbar .custom-container {
  display: flex;
  align-items: center;
}

.banner-left-content p span {
  color: #fff;
  text-transform: uppercase;
  font-size: 31px;
  margin-right: 40px;
}

.banner-left-content p {
  margin-top: 20px;
}

.banner-right-content h3 {
  text-align: center;
  color: #fff;
  font-size: 57px;
  font-weight: 600;
}

.banner-right-content p {
  text-align: center;
  color: #fff;
}

.banner-right-content .btns {
  justify-content: center;
  display: flex;
}

.banner-right-content .btns a:first-child {
  background: var(--green);
  color: #fff;
  padding: 0 18px;
  height: auto;
  line-height: 32px;
  font-size: 17px;
}

.banner-right-content .btns a:last-child {
  border: 1px solid #fff;
  color: #fff;
  padding: 3px 18px;
  height: auto;
  line-height: 30px;
  font-size: 17px;
  margin-left: 12px;
}

body .navbar .logo-img {
  font-size: 30px;
  font-weight: 700;
  color: #3365a0;
}

/* .signupform {
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 35px;
  width: 50%;
} */

/* .container {
  max-width: 1400px;
} */

.left-side {
  width: 100%;
  float: left;
  border: 1px solid #ddd;
  border-top: 4px solid var(--green);
  border-radius: 6px;
  padding: 0;
  margin-top: -19px;
}

.left-side ul {
  margin: 0;
  padding: 0;
}

.left-side ul li {
  list-style: none;
  border-bottom: 1px solid #ddd;
  padding: 24px 30px;
  position: relative;
}

.left-side ul li a {
  color: #fff;
  text-transform: uppercase;
}

.left-side ul li::before {
  width: 8px;
  height: 8px;
  border: 2px solid;
  content: "";
  position: absolute;
  border-top: 0;
  border-left: 0;
  transform: rotate(-45deg);
  left: 11px;
  top: 31px;
}

.left-side ul li:last-child {
  border: 0;
}

.right-side .categories img {
  max-width: 190px;
  max-height: 122px;
}

.right-side .categories h1.title {
  color: #000;
  font-size: 18px;
  margin-top: 8px;
}

.right-side .categories h4.title {
  font-size: 19px;
  font-weight: 400;
  margin: 0 0 10px;
  padding: 0;
}

.right-side .categories select {
  width: 100%;
  float: left;
  max-width: 200px;
  clear: both;
  display: table;
  margin: 0 auto;
  float: none;
  height: 34px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
  padding: 0 10px;
  color: #353535;
}

.add-cart {
  display: flex;
  justify-content: center;
}

.add-cart input[type="number"] {
  border: 1px solid #ddd;
  text-align: center;
  margin-right: 10px;
  border-radius: 5px;
  color: #252525;
}

.add-cart button,
.variant_exist a h1 {
  background: var(--green);
  border: 0;
  text-transform: uppercase;
  color: #fff;
  padding: 6px 25px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.right-side .categories {
  position: static;
  margin-bottom: 40px;
  box-shadow: 0 0 10px 1px #ddd;
  padding: 23px 0 35px;
  border-radius: 9px;
  min-height: 442px;
}

.right-side h4 {
  padding: 0;
  font-size: 36px;
}

ul.pagination {
  justify-content: center;
  margin-bottom: 25px;
}

ul.pagination li {
  margin: 0 7px;
}

.main_all_category_section_class .search input {
  color: #000;
}

ul.pagination li a {
  outline: none;
}

ul.pagination li.active {
  background: var(--green);
  font-weight: bold;
  border-color: var(--green);
}

.product_cat.home-pro-main .row {
  width: 31.5%;
  float: left;
  border: 1px solid #ddd;
  margin: 0 10px 37px;
  padding: 16px 10px;
  border-radius: 10px;
}

.product_cat.home-pro-main .row .col-md-6 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.product_cat.home-pro-main img {
  position: static;
  max-width: 200px;
  min-height: 210px;
  object-fit: cover;
}

.product_cat.home-pro-main .categories {
  position: static;
}

.footer-second {
  width: 100%;
  float: left;
}

/* 
.product_cat.home-pro-main .row .col-md-6 p {
  text-align: center;
  margin-bottom: 1px;
}

.product_cat.home-pro-main .row .col-md-6 a {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  float: left;
} */

/* 
.left-side .search {
  padding: 10px 14px;
  border-bottom: 1px solid #ddd;
} */

/* .left-side .search input[type="text"] {
  width: 100%;
  float: left;
  height: 37px;
  border: 1px solid #ddd;
  padding: 0 11px;
  border-radius: 5px;
  margin-bottom: 10px;
} */

/* .left-side .search button {
  width: 100%;
  height: 37px;
  border-radius: 5px;
  background: var(--green);
  border: 0;
  color: #fff;
  text-transform: uppercase;
} */

.left-side ul li.active {
  background: #fff;
}

.left-side ul li.active a {
  color: var(--green);
}

.left-side ul li.active::before {
  border-color: #00356a;
}

.cst-categorie-sec {
  width: 100%;
  float: left;
}

.product-sec-cstm {
  padding: 85px 0;
}

.product-sec-cstm h1 {
  color: #000;
  font-size: 45px;
  font-weight: 400;
}

.main-qty-sec {
  margin: 0 -15px 15px;
  display: flex;
}

.main-qty-sec .box {
  padding: 0 15px;
  max-width: 50%;
  flex: 0 0 50%;
  -ms-flex: 0 0 50%;
}

.product-sec-cstm select {
  width: 100%;
  height: 45px;
  padding: 0 15px;
  border: 1px solid #ddd;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  margin: 0 !important;
}

.select {
  position: relative;
}

.select::after {
  width: 8px;
  height: 8px;
  border: 2px solid;
  content: "";
  position: absolute;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) translateY(-50%);
  right: 15px;
  top: 70%;
  margin-top: -12px;
  pointer-events: none;
}


.product-slider-text b {
  font-weight: 500;
}

.cart-image img {
  width: 100%;
  max-width: 170px;
}

:focus {
  outline: none;
}

.select-variant-dropdown {
  margin: 0 0 15px;
}

.select-variant-dropdown p {
  margin: 0;
  font-size: 15px;
}


.left-side {
  background: #00356a;
  color: #fff;
}

.Images_product img {
  width: 100%;
  height: auto;
}

.cart-sec {
  padding: 200px 0 85px;
}

.cart-sec .container {
  margin: 0 auto;
}

.cart-sec br {
  display: none;
}

.cart-sec h1.title {
  text-align: center;
  margin-bottom: 0;
}

.cart-heading {
  margin: 20px 0 35px;
}

.cart-sec .col-md-4 {
  border: 1px solid #ddd;
  border-right: 0;
}

.cart-sec .col-md-4>div {
  padding: 15px 0;
  width: 100%;
  float: left;
}

.cart-product-qty-del .main-qty-sec .box {
  max-width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  padding: 0;
}


.cart-product-details br {
  display: block;
}

.cart-product-qty-del .main-qty-sec {
  margin: 0;
  display: flex;
  width: 70%;
  float: left;
}

.checkout-btn .row .col-md-4:first-child {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.checkout-btn .row .col-md-4:last-child {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  float: right;
}

.checkout-btn {
  width: 50%;
  float: right;
}

.checkout-btn .col-md-4 {
  border: 0 !important;
  text-align: right;
  padding: 0;
}

.checkout-btn .col-md-4 br {
  display: block;
}

.checkout-btn ul {
  padding: 0;
  margin: 40px 0;
  list-style: none;
  width: 50%;
  float: right;
}

.checkout-btn ul li {
  text-align: right;
  padding: 0 0 15px;
  margin: 0 0 15px;
  border-bottom: 1px solid #ddd;
}

.checkout-btn ul li:last-child {
  border-bottom: 0;
  padding: 0;
  margin: 0;
}

.checkout-btn ul li strong {
  float: left;
  font-weight: normal;
  text-transform: uppercase;
  font-family: var(--common-fontmd);
  color: #000;
}

.checkout-btn ul li div {
  color: #000;
}

.thumb-slider img {
  width: 120px;
  height: 120px;
  padding: 10px;
  border: 1px solid #ccc;
  margin: 0 auto;
}

.wishlistdata_product .btn-sec .Add_to_cart {
  width: 70%;
}

.cart-sec .select select {
  height: 37px;
  padding: 0 7px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 5px;
}

.cart-sec .select::after {
  display: none;
}

.Cart_product div#qty {
  max-width: 100% !important;
  flex: 0 0 100% !important;
  -ms-flex: 0 0 70%;
}

.pro-name {
  text-transform: capitalize;
}

.Cart_product .col-md-4 {
  border: 0 !important;
}

.pro-cart-table {
  display: flex;
  align-items: center;
}

.container.order-detail-page h3.title {
  text-align: center;
  text-transform: uppercase;
  font-size: 38px;
  margin: 50px 0;
}


.backbutton {
  float: left;
  width: 100%;
}

.order-detail-page .backbutton button.back-button {
  margin: 20px 0;
  text-align: center;
  padding: 10px 50px;
  max-width: 100px;
  display: inline-flex;
  justify-content: center;
}


.edit-checkout-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.edit-checkout-btn button {
  margin-left: 10px;
  background: var(--green);
  border: 0;
}

.radio-bnt {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 17px;
  border-radius: 6px;
  background: #fff;
}

.radio-bnt input {
  margin-right: 13px;
}

.checkout-addrees h5 {
  margin: 0;
  text-transform: capitalize;
  font-size: 16px;
}

span.Total {
  margin-left: auto;
  width: 100%;
  text-align: right;
  padding-right: 16px;
  margin-bottom: 10px;
}

span.amount_paid {
  margin-left: auto;
  width: 100%;
  text-align: right;
  padding-right: 16px;
}

.container.order-detail-page h1 {
  text-align: center;
  text-transform: capitalize;
  /* font-size: 26px; */
  font-size: 22px;
  font-family: var(--inte);
}

.order-sec {
  border: 1px solid #ddd;
  padding: 21px;
  max-width: 400px;
  border-radius: 6px;
  border-top: 4px solid var(--green);
}

.apply-coupn .row {
  border: 0 !important;
}

.apply-coupn .row form.signupform {
  position: static;
  transform: translate(0);
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.apply-coupn button.btn.btn-primary {
  margin: 0 auto;
  display: table;
}

.apply-coupn {
  border: 1px solid #ddd;
  padding: 7px;
  max-width: 500px;
  border-radius: 0px;
  border-top: 4px solid var(--site-purple);
  display: table;
  width: 100%;
  margin: 0 auto 40px;
}

.apply-coupn label {
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
}

.apply-coupn button.btn.btn-primary:last-child {
  margin-top: 10px;
}

.apply-coupn .row button.btn.btn-primary:last-child {
  margin-top: 0;
}

span.Discount {
  width: 100%;
  float: left;
  text-align: right;
}

.apply-coupn button {
  background: var(--green);
  border: 0;
}

.apply-coupn .row form.signupform button {
  margin-left: 8px;
}

.container.checkout-main form button {
  background: var(--green);
  border: 0;
  margin-top: 10px;
}

.container.checkout-main form label.form-label {
  width: 100%;
  float: left;
  margin: 10px 0 4px;
  font-family: var(--common-fontmd);
  font-weight: normal;
}

.container.checkout-main button {
  background: var(--green);
  color: #fff;
  text-transform: uppercase;
  border: 0;
  font-size: 15px;
  padding: 8px 15px;
  border-radius: 40px;
  margin: 13px 0 19px;
  height: 40px;
  border: 2px solid var(--green);
  transition: 0.3s ease all;
}

.container.checkout-main h4 {
  text-align: center;
  background: var(--site-purple);
  color: #fff;
  padding: 15px;
  border-radius: 0;
  font-size: 14px;
  font-weight: 600;
}

.thnkyou-page {
  max-width: 640px;
  margin: 33px auto 80px;
  box-shadow: 0 0 10px 1px #ddd;
  padding: 15px;
  background: #fff;
  text-align: center;
}

.thnkyou-page h3 {
  font-size: 16px;
}

.Cart_product {
  background: #fff;
  padding: 10px 20px;
  margin-bottom: 0;
}


@media (max-width: 767px) {
  .Cart_product {
    margin-bottom: 30px;
    /* min-width: 290vw; */
  }
}

.thn-lft,
.thn-rgt {
  border: 1px solid #ddd;
  padding: 20px;
  background: #fff;
  margin-bottom: 30px;

}

.add-cart .main-qty-sec .box {
  max-width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
}

.add-cart .main-qty-sec .box div#qty button {
  border-radius: 0;
  color: #000 !important;
}

.add-cart .main-qty-sec {
  width: 50%;
  max-width: 50%;
  flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  padding-right: 9px;
  float: left;
}

.add-cart .main-qty-sec .box div#qty {
  max-width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
}

form.signupform.main-sign-frm {
  position: static;
  transform: translate(0);
  justify-content: space-between;
  margin: 0 auto;
  background: #fff;
  margin-bottom: 25px;
  border-radius: 0px;
  padding: 55px;
  max-width: 650px;
  width: 100%;
  position: relative;
  z-index: 2;
}

form.signupform.main-sign-frmm {
  width: 100%;
  margin: 27px auto;
  max-width: 985px;
}

form.signupform.main-sign-frm-commission {
  width: 100%;
  margin: 27px auto;
  max-width: 1047px;
}

form.signupform.main-sign-frm .Custom_filter input[type="tel"],
form.signupform.main-sign-frm .Custom_filter input[type="text"] {
  max-width: 100px;
  height: 40px;
  padding: 0 0px;
  line-height: 40px;
  border: 0 none;
  border-bottom: 1px solid #ddd;
  border-radius: 6px;
  background: #FFF;
}

form.signupform.main-sign-frm .Custom_filter input[type="text"],
form.signupform.main-sign-frm .Custom_filter input[type="tel"] {
  border: 1px solid #ddd;
  padding: 0 10px;
}

form.signupform.main-sign-frm .MuiFormControl-root {
  background: none;
}


.sign-up-bnt button,
form.signupform.main-sign-frm button.btn.btn-primary {
  background: var(--green);
  margin-right: 8px;
  float: left;
}

.locat_test li a {
  color: #000;
}

form.signupform.main-sign-frm a {
  color: var(--green);
  text-transform: capitalize;
  text-decoration: none;
  font-family: var(--common-font);
}

.signupform span {
  color: red;
  font-size: 13px;
}

nav.navbar.navbar-expand-lg.navbar-dark.bg-primary {
  background: var(--green) !important;
}

.locat_test li input[type="button"] {
  background: var(--green);
  color: #fff;
  border: 0;
  padding: 1px 13px 3px;
  font-size: 16px;
  border-radius: 4px;
  margin-top: 4px;
}

.profile-page-main h3 {
  text-align: center;
}

.container.checkout-main button.dlt {
  margin: 0;
  font-size: 15px;
  margin-left: auto;
  float: right;
  background: transparent;
  padding: 5px 10px 0;
  color: red;
}

.locat_test {
  z-index: 9;
}

ul.navbar-nav.mr-auto li a input {
  color: #000;
  text-transform: capitalize;
  background: var(--green);
  color: #fff;
  border: 0;
  padding: 1px 13px 3px;
  font-size: 16px;
  border-radius: 4px;
  margin-top: 0;
  width: 100%;
  margin: 0;
}

.container.checkout-main .mb-3 span,
.container.checkout-main .mb-6 span,
.profile-page-main form span {
  color: red;
  font-size: 13px;
}

.profile-page-main {
  width: 100%;
  float: left;
  padding-bottom: 60px;
  margin-top: 110px;
}

.locat_test li a {
  color: #000;
}

.pro-fil-user {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  margin-top: 69px;
}

.profile-page-main button {
  margin: 18px auto 0;
  display: table;
  background: var(--green);
  border: 0;
  color: #fff;
}

.profile-page-main form .row {
  margin: 0;
}

.cart-sec h3.title,
.cart-sec h4 {
  text-align: center;
}

span.Discount,
span.Total,
span.amount_paid {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

.container.checkout-main.checkout_main_section_class span.Discount strong,
.container.checkout-main.checkout_main_section_class span.Total strong,
.container.checkout-main.checkout_main_section_class span.amount_paid strong {
  padding-right: 10px;
  font-size: 16px;
}

.howar i,
ul.navbar-nav.mr-auto li a i {
  font-size: 20px;
}

.profile-page-main form {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
}

body .locat_test li a {
  color: #000;
}

.Add_to_cart.out-of-stock button {
  cursor: not-allowed;
  opacity: 0.5;
  border-radius: 40px;
}

.locat_test li {
  padding: 1px 10px !important;
}

ul.navbar-nav.mr-auto li:nth-last-child(3) {
  /* display: none; */
  padding-left: 0;
}

.container.order-detail-page .col-md-2 a {
  color: var(--green);
}

p.title.pro-price {
  display: flex;
  justify-content: center;
}

p.title.pro-price b {
  padding-right: 6px;
}

.row.addres-page {
  /* margin-top: 110px; */
  margin-top: 0px;
  float: left;
  width: 100%;
}

.row.addres-page .radio-bnt {
  position: relative;
}

.row.addres-page button.edit-address {
  float: right;
  margin-left: auto;
  margin-right: 30px;
  background: var(--green);
  border: 1px solid var(--green);
  color: #fff;
  padding: 10px 15px;
  line-height: 1;
  border-radius: 5px;
}

.inner-div {
  width: 100%;
  float: left;
  min-height: 900px;
}

.Product_details p {
  display: flex;
}

.Product_details p b {
  padding-right: 8px;
}

.order-detail-sec .row {
  padding-bottom: 0;
  border: 0;
  margin: 0;
}

.order-detail-sec .col-md-4,
.order-detail-sec .col-md-8 {
  border: 1px solid #ddd !important;
  padding: 15px;
}

.order-detail-sec .col-md-4 {
  border-left: 0 !important;
}

.cstm-tittle,
.order-detail-sec .tittle {
  padding: 10px;
  background-color: var(--site-purple);
  border-color: #000;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin: 0 0 15px;
  font-size: 18px;
  font-family: var(--inte);
}

.order-cstm h4.cstm-tittle {
  margin-top: 40px;
}

.order-cstm .thn-lft {
  margin-top: 40px;
  border-color: #e3e3e3;
  background-color: #f3f3f3;
  padding: 0 0 40px;
  margin-bottom: 0;
  overflow: hidden;
}

.order-cstm .thn-lft h4 {
  background-color: var(--site-purple);
  border-color: #000;
  text-align: center;
  color: #fff;
  font-family: var(--inte);
  text-transform: uppercase;
  margin: 0;
  border: 10px;
  font-size: 18px;
  padding: 15px;
  border-radius: 0;
}

td.cstmsub-total-sub {
  font-family: var(--common-fontmd);
  text-transform: uppercase;
}

.order-cstm .thn-lft div {
  padding: 0 15px;
}

button.re-order {
  background: var(--green);
  border: 1px solid var(--green);
  color: #fff;
  padding: 15px 40px;
  font-size: 18px;
  line-height: 1;
  display: table;
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: 6px;
}

.col-md-3 {
  margin-top: 10%;
}


.addres-page.row .col-md-3 {
  margin: auto;
}

.profile-page-main .container .row .col-md-3 {
  margin-top: auto;
}

.inner-div .container .row.addres-page .col-md-3 {
  margin: auto;
}

.inner-div .container .row.addres-page .col-md-9 {
  margin: auto;
}

.mainorder-detail-sec {
  padding: 190px 0 85px;
  width: 100%;
  float: left;
  background: linear-gradient(90deg, #e3e6e8 0%, #d0d5da 100%);
}

.mainorder-detail-sec .container.order-detail-page h3.title,
.mainorder-detail-sec h3.title {
  margin: 0 0 25px;
  text-align: left;
  font-size: 24px;
  padding: 15px;
  background: var(--green);
  color: #fff;
}

/* .mainorder-detail-sec .container.order-detail-page {
  padding: 0 0 20px;
  margin: 0;  
}*/

.mainorder-detail-sec .container.order-detail-page .export-to-xls button {
  margin: 0px;
}

.mainorder-detail-sec .container.order-detail-page button {
  border: 2px solid var(--green);
  background: var(--green);
  color: #fff;
  padding: 10px 30px;
  font-size: 16px;
  border-radius: 50px;
  height: unset;
  line-height: 1;
}

.mainorder-detail-sec .container.order-detail-page button.MuiIconButton-sizeSmall {
  padding: 7px 25px !important;
  font-family: var(--common-font-Lt);
}

.mainorder-detail-sec .container.order-detail-page table button[type="button"] {

  padding: 3px 10px;
}

.mainorder-detail-sec .container.order-detail-page button:hover,
.main-invitaion a.btn.btn-primary:hover {
  background: #fff;
  color: var(--green);
}

.mainorder-detail-sec .container.order-detail-page .row {
  align-items: flex-start;
}

.mainorder-detail-sec .profile-page-main {
  margin: 0;
}

.mainorder-detail-sec .profile-page-main .container .row .col-md-3,
.mainorder-detail-sec .pro-fil-user {
  margin-top: 0;
}

.mainorder-detail-sec .container .col-md-9.dashboard-main {
  margin: 0;
  padding: 0 15px;
}

.mainorder-detail-sec .container .row.addres-page .col-md-3 {
  margin: 0 !important;
}

ul.pagination li a {
  padding: 3px 11px;
  display: block;
}

ul.pagination li.active,
ul.pagination li.active a {
  color: #fff;
}

ul.pagination .next.disabled,
ul.pagination .previous.disabled {
  display: none;
}

.mainorder-detail-sec .main-qty-sec {
  margin: 0;
}

.mainorder-detail-sec .main-qty-sec .box {
  margin: 0 auto;
  max-width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
}

.mainorder-detail-sec .Cart_product.order-detail-sec .row {
  padding-bottom: 0;
  border-bottom: 0;

}

.mainorder-detail-sec .Cart_product.order-detail-sec .billing-arcstm .row {
  display: flex;
  gap: 30px;
}

.mainorder-detail-sec .Cart_product.order-detail-sec .row::before,
.mainorder-detail-sec .Cart_product.order-detail-sec .row::after {
  display: none;
}

.mainorder-detail-sec .Cart_product.order-detail-sec .row .col-md-6:first-child,
.mainorder-detail-sec .Cart_product.order-detail-sec .row .col-md-6:last-child {
  padding: 0;
}

.mainorder-detail-sec .Cart_product.order-detail-sec .row .col-md-6 {
  width: 100%;
}


.mainorder-detail-sec .col-md-4.qty-sec {
  padding: 7px;
  border-left: 0;
}

.mainorder-detail-sec .order-detail-sec .row .col-md-4:first-child {
  border-left: 1px solid #ddd !important;
}

.mainorder-detail-sec .backbutton {
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}

.skip-false button {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.sing-out .fa {
  cursor: pointer;
  color: #fff;
  font-size: 22px;
}

/* .mini-cart-sec .container .mini-cart .mini-cart-des .main-qty-sec {
  justify-content: flex-start;
} */

.mini-cart-sec .container .mini-cart .mini-cart-des .main-qty-sec .box {
  margin: 0;
  max-width: 70%;
  flex: 0 0 70%;
  -ms-flex: 0 0 70%;
  padding-left: 0;
}

.mini-cart-sec .select {
  width: 70%;
}

.mini-cart-sec .select::after {
  margin-top: -11px;
}

.mini-cart-sec select {
  width: 100%;
  /* -webkit-appearance: none; */
  /* -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none; */
  /* appearance: none; */
  background-color: transparent;
  border: 1px solid #ddd;
  margin-bottom: 6px;
  height: 35px;
  padding: 0 15px;
  font-size: 14px;
  max-width: 140px;
}

.mini-cart-sec .Cart_product>h3.title {
  text-align: center;
  padding: 15px 0 0;
  font-size: 16px;
  color: #f00;
}

.cart-product-details .title {
  margin: 0;
}

.mini-cart-sec .container {
  margin: 0;
}

li.nav-item input[type="button"] {
  border: 1px solid #fff;
  color: #fff;
  background-color: transparent;
}

.container.order-detail-page .apply-coupn .row {
  padding: 0;
  margin-bottom: 0;
}

.mainorder-detail-sec .col-md-3 {
  margin-top: 0;
}

.row.addres-page button.add-address {
  background: var(--green);
  border: 2px solid var(--green);
  color: #fff;
  padding: 10px 15px;
  line-height: 1;
  transition: 0.3s ease all;
  float: right;
  margin-top: 15px;
  align-items: center;
  display: flex;
  gap: 5px;
}

.add-address-sec {
  width: 100%;
  float: left;
  margin-top: 25px;
}

ul.pagination {
  width: 100%;
  float: left;
}

.cstm-btns-sec {
  margin-top: 15px;
}

.cstm-btns-sec button {
  background: var(--green);
  border: 1px solid var(--green);
  color: #fff;
  padding: 10px 15px;
  line-height: 1;
  border-radius: 5px;
}

.cstm-btns-sec button:last-child {
  margin-left: 15px;
}

.select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.row.addres-page textarea {
  resize: none;
  height: 100px;
  padding: 15px;
  border-color: #ddd;
}

:focus {
  outline: none;
  box-shadow: none;
}

span.error {
  font-size: 14px;
  color: #f00;
  position: relative;
  top: 10px;
}

.signup-news span.error {
  display: block;
  padding: 10px;
  background: #fff;
}

.field-class {
  margin-bottom: 15px;
}

.userdetails {
  display: table;
  margin: 20px auto;
  padding: 10px;
  border: 1px solid #ddd;
}

.userdetails strong {
  margin-right: 10px;
}

ul.navbar-nav.mr-auto svg {
  fill: #fff;
  cursor: pointer;
}

ul.navbar-nav.mr-auto .count,
ul.navbar-nav.mr-auto li {
  color: #fff;
}

.notification_bar {
  position: relative;
  padding: 0 5px;
}

.notification_bar ul {
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ddd;
  border-top: 4px solid var(--green);
  border-radius: 6px;
  background-color: #fff;
  width: 200px;
  margin-top: 35px;
  transition: 0.3s all linear;
  opacity: 0;
  visibility: hidden;
}

.notification_bar ul li {
  padding: 10px !important;
  margin: 0;
  border-bottom: 1px solid #ddd;
  color: #000 !important;
  text-align: left;
  font-size: 15px;
}

.notification_bar:hover ul {
  opacity: 1;
  visibility: visible;
  margin-top: 19px;
}

.cart-product-details .select {
  display: table;
  width: 80%;
}

.cart-product-details select {
  width: 100%;
}

.cart-product-details .select::after {
  width: 8px;
  height: 8px;
  border: 2px solid;
  content: "";
  position: absolute;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) translateY(-50%);
  right: 15px;
  top: 50%;
  margin-top: -10px;
  display: block;
}

.mini-cart-des .cart-product-details .select::after {
  top: 70%;
}

.pro-cart-table .Add_to_cart button {
  width: 130px;
}

.delete-autoship-order svg {
  fill: #f00;
  display: table;
  margin: 0 0 0 10px;
}

.search.selection.dropdown .menu {
  background-color: #fff;
  width: 100%;
  padding: 20px 0 0;
  border: 1px solid #ddd;
  /* border-top: 4px solid var(--green); */
  border-radius: 0px;
  margin: 30px 0;
  cursor: pointer;
}


.search.selection.dropdown .menu .item {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding: 15px 20px;
  border-top: 1px solid #ddd;
  /* border-left: 1px solid #ddd; */
}

.search.selection.dropdown .menu .item:first-child,
.search.selection.dropdown .menu .item:nth-child(2) {
  border-top: 0;
}

.search.selection.dropdown .menu .item img {
  /* width: 60px; */
  height: 60px;
  object-fit: cover;
  padding: 5px;
  border: 1px solid var(--green);
  margin-right: 15px;
}

.search.selection.dropdown .divider.text {
  margin: 40px 0;
}

.search.selection.dropdown .divider.text img {
  width: 120px;
  height: 120px;
  object-fit: contain;
  padding: 5px;
  border: 1px solid #ddd;
  margin-right: 15px;
  border-radius: 3px;
}

.search.selection.dropdown input[type="text"] {
  width: 50%;
  height: 45px;
  padding: 0 15px;
  border: 1px solid #ddd;
  margin: 15px 0 0;
  border-radius: 3px;
}

.search.selection.dropdown .divider.text {
  display: none;
}

.mainorder-detail-sec .delete svg {
  fill: #f00;
  cursor: pointer;
  margin: 10px 15px 0 0;
}

svg {
  cursor: pointer;
}

.changeadress-button {
  padding: 0px;
  margin: 10px 0 20px;
  width: 100%;
}

.changeadress-button button {
  float: right;
}

.edit svg {
  cursor: pointer;
  color: var(--green);
  width: 20px;
  height: 20px;
}

.skip-true button {
  text-transform: uppercase;
}

.row.addres-page button.add-address {
  margin-top: 0;
  margin-bottom: 15px;
}

.row.addres-page ul.pagination {
  margin: 15px 0 0;
}

.pro-name {
  font-size: 15px;
}

.row.addres-page button.btn.btn-primary+svg {
  float: right;
  margin-left: auto;
  margin-right: 30px;
  cursor: pointer;
  color: var(--green);
  width: 20px;
  height: 20px;
}

.container.checkout-main h1.title {
  margin: 30px 0 25px;
  font-size: 24px;
  color: var(--green);
  font-family: var(--inte-BD);
  text-align: center;
  position: relative;
  right: 338px;
}

.cart-product-details .title div {
  font-size: 14px;
}

.thnku-sec {
  padding-top: 180px;
}

.thnku-sec.kit_thankyoupage {
  padding-top: 0;
}

.icon-thank {
  text-align: center;
}

.icon-thank svg {
  width: 70px;
}

.thnku-sec h2 {
  text-align: center;
}

.thnku-sec h4 {
  font-size: 22px;
  margin-bottom: 20px;
  /* text-transform: uppercase; */
}

.header-menu ul li {
  position: relative;
  margin: 0 8px;
  padding: 0;
}

.header-menu ul li .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  border-top: 4px solid var(--green);
  display: block;
  margin-top: 20px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s all linear;
  padding: 0;
}

.header-menu ul li:hover .dropdown-menu {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.header-menu ul li .dropdown-menu li {
  color: #000;
  padding: 10px !important;
  border-bottom: 1px solid #ddd;
}

.header-menu ul li .dropdown-menu li:last-child {
  border-bottom: 0;
}

.header-menu ul li,
.header-menu ul li i,
.header-menu ul li svg {
  cursor: pointer;
}

.header-menu ul li .icon-box {
  position: relative;
}

.header-menu ul li .icon-box span {
  position: absolute;
  top: -5px;
  right: -8px;
  width: 17px;
  height: 17px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.header-menu ul li .dropdown-menu li.btn-li {
  padding: 0 !important;
}

.header-menu ul li .dropdown-menu li.btn-li .btn {
  width: 100%;
  border: 0;
  background-color: var(--green);
  color: #fff;
  padding: 15px;
  line-height: 1;
}

.header-menu ul li:nth-child(3) {
  padding-right: 5px;
}

.view-icon svg {
  margin-left: 10px;
}

.header-menu ul li .dropdown-menu li {
  display: flex;
}

.header-menu ul li .dropdown-menu li img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 15px;
}

.changeadress-button button:last-child {
  margin-right: 15px;
}

.banner-sec {
  height: 710px;
}

.container.order-detail-page .disable button {
  background: #33c2eb8f;
  border: none;
  cursor: not-allowed;
}

.col-md-2.pro-name.action-text {
  text-align: center;
  float: none;
  min-width: 400px;
}


/* .order-cstm .radio-bnt {
  padding: 10px !important;
  margin: 0 15px 10px;
} */

/* .mainorder-detail-sec .row.order-cstm h5 {
  font-size: 13px !important;
  font-weight: 500 !important;
  line-height: 22px;
} */

.mainorder-detail-sec .order-cstm .thn-lft {
  margin-top: 0px;
  border-color: #ddd;
  background-color: #fff;
  padding: 0 0;
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none;
}

.mainorder-detail-sec .order-cstm .thn-lft h2 {
  margin: 0px;
  font-size: 15px;
  background: var(--site-purple);
  color: #FFF;
  padding: 15px;
  line-height: 1;
  font-weight: 600;
}

button.add-product-autoshiporder {
  margin-bottom: 13px;
}

.title.pro-price {
  display: flex;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.title.pro-price b {
  padding-right: 6px;
}

.title.product-page-peice {
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: left;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 30px;
}

.title.product-page-peice b {
  padding-right: 6px;
}

li.bell_dropdowon.dropdown.notifications-menu ul li:hover {
  background: var(--green);
  color: #fff;
  margin: 0;
}

.header-menu ul li .dropdown-menu {
  top: 46px;
}

.header-menu ul li ul.dropdown-menu.cart-notification {
  top: 40px;
  max-height: 225px;
  overflow-y: auto;
}

.button-top {
  text-align: right;
  float: right;
  width: 98%;
  margin-bottom: 33px;
}

.cart-notification {
  overflow-y: auto;
  min-height: 100px;
}

li.nav-item .cart-notification {
  overflow-y: auto;
  height: 283px;
  width: 330px !important;
}

.txt {
  font-weight: normal;
  font-size: 14px;
}

.forgot.password {
  padding: 1px 0 18px;
}

button.btn.btn-primary.sigup {
  float: right;
}



.container.order-detail-page h5 {
  background: transparent;
  color: #000;
}

.backbutton .back-button {
  background: var(--green);
  color: #fff;
  width: 89px;
  height: 37px;
  border-radius: 5px;
  font-weight: 700;
  border: 1px solid var(--green);
}

textarea.refund-message-box {
  width: 100%;
  height: 200px;
}

.cart-product-details {
  display: flex;
  align-items: center;
}

.cart-product-details-img {
  max-width: 100px;
  margin-right: 10px;
}

.cart-product-details-img img {
  width: 100%;
  object-fit: contain;
}

.cart-product-details .cart-product-details input[type="checkbox"] {
  margin-right: 5px;
}

.cart-product-details .col-md-4.qty-sec {
  padding: 0;
  border: 0 !important;
  margin-left: auto;
}

.cart-product-details .col-md-4.qty-sec .box {
  margin: 0 auto;
  max-width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  padding: 0;
}

.cart-product-details .col-md-4.qty-sec .box #qty button:last-child {
  right: 0;
  border-left: 1px solid #ddd;
}

.Cart_product.order-detail-sec .row {
  align-items: inherit;
}

.Cart_product.order-detail-sec {
  padding: 0;
}

.refund .thn-lft {
  background-color: transparent !important;
  border: 0;
}

.bundle-product {
  margin: 0 0 30px;
}

.bundle-product h1.title {
  text-transform: capitalize;
  margin: 30px 0 10px;
}

textarea {
  padding: 15px;
  border: 1px solid #ddd;
  resize: none;
}

.btn-sec-cstm {
  margin-top: 25px;
}

.checkout-addrees h5 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.container.order-detail-page .button-parrt {
  width: auto;
  float: left;
  position: relative;
}

.row.addres-page .add-address-sec+.row {
  align-items: inherit;
}

.row.addres-page button.btn.btn-primary {
  padding: 0;
}

div#treeWrapper {
  height: 500px !important;
  border: 1px solid #ddd;
}

.rd3t-tree-container {
  width: 100%;
  height: 100%;
  overflow: visible;
}

.rd3t-grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.rd3t-grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

/* Node */

/* Link */
.rd3t-link {
  fill: none;
  stroke: #000;
}

.rd3t-svg {
  overflow: visible;
  height: 100%;
  width: 101%;
  position: relative;
}

.node__root>circle {
  fill: red;
}

.rd3t-tree-container {
  overflow: scroll;
}

.rd3t-svg {
  overflow: visible;
  height: 100%;
  width: 101%;
  position: relative;
  margin: 17% auto 48% 112%;
}

ul.tabs {
  margin: 0;
  padding: 0 0 0 20px;
  text-align: left;
  list-style: none;
}

ul.tabs li {
  padding: 0;
  display: inline-block;
  vertical-align: top;
  max-width: inherit;
  flex: inherit;
  -ms-flex: inherit;
  width: auto;
}

ul.tabs li button {
  display: inline-block;
  background: transparent;
  color: var(--green);
  border: 1px solid var(--green);
  padding: 0 18px;
  height: auto;
  line-height: 32px;
  font-size: 17px;
  cursor: pointer;
  font-weight: 400;
  text-align: center;

  border-radius: 5px;
}

ul.tabs li.active button {
  background: var(--green);
  border: 1px solid var(--green);
  color: #fff;
}

.level-box {
  border: 1px solid #ddd;
  margin-top: -1px;
}

.Level {
  border: 1px solid #ddd;
}

.Level h2 {
  background: #621D87;
  margin: 0;
  color: #fff;
  text-align: center;
  padding: 0;
  font-size: 17px;
  text-transform: uppercase;
}

.Level .col-md-6 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.Level .box {
  border-top: 1px solid #ddd;
  position: relative;
  padding: 15px;
  background: #f0f0f0;
}


.Level .box svg {
  width: 30px;
  height: 24px;
}

.Level .box .icons {
  position: absolute;
  top: 15px;
  right: 15px;
}

.Level .box .first_name,
.Level .box .user_rank {
  padding-left: 45px;
  text-align: left;
}

.Level .box .first_name h3,
.Level .box .user_rank h3 {
  font-size: 20px;
}

.Level .box .data {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  font-size: 14px;
}

.Level .box .data strong {
  width: 50%;
  float: right;
}

.Level .box svg:first-child {
  position: absolute;
  left: 15px;
  top: 15px;
}

.Level .box .icons svg {
  width: 24px;
  height: 25px;
  position: relative;
  top: auto;
  left: auto;
  display: inline-block;
  margin: 0 4px;
}

.Level .box .icons {
  position: absolute;
  top: 15px;
  right: 15px;
}

.lavel-box {
  padding: 0px 20px;
  border: 1px solid #ddd;
}

.lavel-box .row {
  margin: 0 -10px;
}

.lavel-box .row .col-md-3 {
  padding: 0 10px;
}

.Level .col-md-6 {
  padding: 0 10px;
}

.downline_users {
  padding-bottom: 80px;
  width: 100%;
}

.downline_users .Level {
  height: 800px;
  overflow-y: auto;
}

/* .container {
  max-width: 1440px !important;
  width: 100% !important;
} */



.col-md-9.dashboard-main .title+.signupform.main-sign-frm {
  margin: 0 0 30px !important;
  width: 100%;
  border: 0;
  background: transparent;
  box-shadow: none;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin: 0 10px 0 0;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm .row {
  margin: 0 10px 0 0;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm input,
.col-md-9.dashboard-main .title+.signupform.main-sign-frm select {
  margin: 0 5px;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm input[type="text"] {
  height: 37px;
  padding: 0 10px;
  border: 1px solid #ddd;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm select {
  margin: 0 5px !important;
  height: 37px;
  padding: 0 10px;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: #fff url("https://dorecom.com/wp-content/uploads/2021/05/arrow.png") no-repeat center right 10px;
  background-size: 10px;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm .get_commision {
  background: var(--green) !important;
  color: #fff;
  padding: 8px 12px;
  height: 37px;
  line-height: 32px;
  font-size: 16px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border: 0;
  line-height: normal;
  border-radius: 5px;
}

.commission-table {
  width: 100%;
  border: 0;
  border: 1px solid #ddd;
}

.commission-table thead th {
  border: 1px solid #ddd;
  background: #f9f9f9;
  padding: 5px;
}

.commission-table tbody tr td {
  border: 1px solid #ddd;
  padding: 15px 2px;
  ;
}

.commission-table tbody .date td {
  background: var(--green) !important;
  color: #fff;
}

.commission-table tbody tr:nth-child(2n) td {
  background: #f9f9f9;
  color: #000;
}

.commission-table tbody tr td:last-child {
  display: flex;
  justify-content: center;
  height: auto;
  gap: 15px;
  margin-top: 30px;
}

.commission-table tbody tr td:last-child div {
  padding: 0 10px;
}

.first_name h3 {
  font-size: 16px !important;
}

.user_rank h3 {
  font-size: 16px;
}

.groups.tabs ul li {
  margin-right: 7px;
  margin-bottom: 21px;
  margin-left: 28px;
}

li.active button {
  background: #fff !important;
  border: 1px solid var(--green) !important;
  color: var(--green) !important;
}

.box svg {
  width: 16px !important;
}

.lavel-box .first_name {
  padding-left: 22px !important;
  padding-top: 6px !important;
}



.commission-table td div {
  display: inline;
}

.pendingclawback {
  border: 1px solid #ddd;
}

.pendingclawback .col-md-4 {
  border-bottom: 1px solid #ddd;
}

.pendingclawback .row {
  margin-left: 0;
  margin-right: 0;
}

.pendingclawback .col-md-4 {
  border-bottom: 1px solid #ddd;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 12px;
  text-align: left;
}

ul.pagination {
  margin: 20px 0 0;
}

/* .container {
  max-width: 1672px !important;
  width: 100% !important;
} */

.left-side {
  height: 100%;
}

div#treeWrapper {
  width: 1214px !important;
}

.rd3t-svg {
  overflow: visible;
  height: 100%;
  width: 100%;
  position: relative;
  margin: 8% auto 48% 66%;
}

.rd3t-svg rect {
  width: 171px;
  text-align: left;
  height: 170px;
}

text {
  transform: translate(-51px, 38px);
  font-size: 12px;
}

.usericon svg {
  width: 200px !important;
  height: 41px;
}

.user-Reser h5 {
  font-size: 16px;
  padding: 0;
  font-weight: 500;
  margin-bottom: 3px;
}

.icons svg {
  margin-right: 14px;
  width: 18px;
  height: 25px;
}

.deatis-info {
  /* margin-top: -21px; */
  /* border-top: 2px solid #000; */
  padding-top: 13px;
}

.current-part {
  margin-top: 10px;
  border-top: 2px solid #000;
  padding: 10px 0 0;
}

.current-part h3 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
}

.active-block h3 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
}

.active-block {
  border-top: 2px solid #000;
  margin: 10px 0 0;
  padding-top: 10px;
}

.active-block p {
  text-align: left;
  position: relative;
}

.active-block p:after {
  position: absolute;
  content: "\F00C";
  font-family: "FontAwesome";
  left: 0;
}

.active-block p {
  text-align: left;
  position: relative;
  line-height: 9px;
  padding-left: 27px;
  border-bottom: none;
  margin-top: 24px;
}

.active-block hr {
  display: none;
}

.deatis-info {
  margin-top: 20px;
}

.current-part {
  padding: 10px;
}

.Distributor.row {
  text-align: center;
  margin: 0 auto;
  width: 48%;
}

.Distributor.row h4 {
  font-size: 14px;
}

.dt-jned {
  font-size: 14px;
}

.Referal.code {
  font-size: 12px;
}

.Referal_url {
  font-size: 12px;
}

.Toal-Sale {
  font-size: 12px;
}

.Toal-commission {
  font-size: 12px;
  padding-right: 30px;
  position: relative;
}



.active-block {
  padding-left: 10px;
}

.active-block p {
  text-align: left;
  position: relative;
  line-height: 9px;
  padding-left: 27px;
  border-bottom: none;
  margin-top: 13px;
  font-size: 12px;
}

.leftsec {
  border: 2px solid #000;
  width: 100%;
  margin: 0 auto 0 0;
  padding-top: 14px;
  padding-bottom: 10px;
}

.DownlineStatic h3 {
  font-size: 15px;
}

.DownlineStatic {
  margin: 20px 0 0;
  text-align: left;
  border: 2px solid #000;
  width: 100%;
  padding: 10px;
}

.Distributor.row h4 {
  font-size: 14px;
  padding-top: 8px;
}

.col-md-8.statis_datas svg {
  width: 160px;
  height: 160px;
}

.Distributor.row svg {
  width: 134px;
}

.chart-LineGraph {
  width: 100%;
}

.chart-main {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  /* gap: 15px; */
}

.chart-main .total_commissonValue {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.chart-main h3 {
  display: none;
}

.total_commissonValue>div:first-child {
  font-weight: 700;
  color: #000;
}

/* .chart-main>div {
  max-width: calc(100%/2);
} */

.chart-PieCharts svg {
  max-width: 200px;
  height: 250px;
}

.Distributor.row svg {
  width: 134px;
  height: 39px;
}

.groups.tabs .tree li {
  margin-right: 0 !important;
}

.tree {
  width: 2300px;
}

.groups.tabs {
  width: 100%;
  float: left;
  /* overflow-x: scroll; */
}

/* end custom design tree */

.top-head-commission {

  display: flex;
}

.left-commison-sec {
  float: left;
  text-align: left;
}

.headsection-graphical {
  border: 2px solid #000;
  margin: 20px 0;
  padding: 10px;
}

.commsion.header {
  display: flex;
  border-bottom: 1px solid #000;
  justify-content: space-between;
  padding: 0px 0 10px;
  align-items: center;
}

.common-static-left {
  text-align: left;
}

.common-static-left h3 {
  font-size: 16px;
  font-family: var(--common-fontmd);
  margin: 0;
}

.common-static-right {
  font-size: 13px;
}

.headsection-graphical form.signupform.main-sign-frm {
  margin: 15px 0 0;
  width: 100%;
  border: none;
  box-shadow: none;
  padding: 0;
  display: flex;
  background: none;
}

.Status {
  display: flex;
}

.date_range_filter {
  font-size: 13px;
  vertical-align: top;
}

.order_id_filter {
  display: flex;
  font-size: 13px;
  padding-left: 15px;
  padding-right: 15px;
  /* color: #fff; */
}

.order_id_filter input {
  border-radius: 32px;
  height: 35px;
  padding: 10px;
  border: 2px solid #ddd;
  width: 124px;
}

.date_range_filter input[type="radio"] {
  verti-align: middle;
  margin-left: 6px !important;
  margin-top: -5px !important;
}

.Status {
  display: flex;
  font-size: 13px;
}

.Status select {
  margin-left: 10px;
  margin-right: 10px;
}

.get_commision {
  margin-left: 36px;
  background: none;
  margin-top: -2px;
}

.get_commision button {
  background: #00356a;
  border: none;
  border-radius: 3px;
  margin-top: 0;
  color: #fff;
  font-size: 13px;
  padding: 4px 20px;
  border-radius: 4px;
  vertical-align: top;
  transition: 0.3s ease all;
  border: 2px solid #00356a;
}

.get_commision button:hover {
  background: #fff;
}

.headsection-graphical {
  margin: 0;
  padding: 0px;
  border: none;
  margin-bottom: 25px;
}

.main-top {
  margin: 20px 0;
  padding-bottom: 60px;
}

.chart-PieCharts h2 {
  font-size: 17px;
}

.commsion-weekly-dashboard {
  border: 2px solid #000;
  padding: 10px;
}

.dashboard-commsion-header {
  display: flex;
  border-bottom: 1px solid;
  padding: 2px 0;
  justify-content: space-between;
}

.left-data-dashboard {
  float: left;
  text-align: left;
  font-family: var(--common-fomntmd);
  font-size: 16px;
}

.headingData {
  margin: 10px 0;
  float: left;
  font-weight: 600;
  color: #000;
}


/* 
canvas {
  width: 547px;
  float: left;
  text-align: left;
} */

.rd3t-node {
  cursor: pointer;
  fill: #fff;
  stroke: #000;
  stroke-width: 2;
}

.left-commison-sec {
  display: flex;
}

.currentrank {
  display: flex;
  flex-wrap: wrap;
}

.current-rank-left {
  width: 153px;
}

.Toal-Sale,
.Toal-commission {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total-main-now {
  display: flex;
}

.total-main-now div:first-child {
  width: 238px;
}

.total-main-now {
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
  font-size: 13px;
}

.current-rank-right h3 {
  font-size: 13px;
}

h3.title1-heading {
  font-size: 11px;
}

.export-to-xls {
  text-align: right;
  max-width: calc(100% - 40px);
  width: 100%;
  margin-bottom: 23px;
  margin-right: auto;
  margin-left: auto;
}



.comistion-table-data {
  max-height: inherit;
  height: auto;
  /*overflow-y: auto;*/
  float: left;
  width: 100%;
}

table.commission-table th {
  position: sticky;
  top: 0;
  z-index: 2;
}


.headingData {
  margin: 24px 0 13px;
}

.order_id_filter {
  margin: 0;
}

span.status-head-1 {
  color: #000;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm input[type="text"] {
  height: 27px;
  padding: 0 10px;
  border: 1px solid #ddd;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm select {
  margin: 0 5px !important;
  height: 27px;
  padding: 0 10px;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: #fff url("https://dorecom.com/wp-content/uploads/2021/05/arrow.png") no-repeat center right 10px;
  background-size: 10px;
}

.mainorder-detail-sec .get_commision {
  text-align: right;
  margin-left: 0;
  width: 100% !important;
  max-width: max-content;
}


.Custom_filter {
  display: flex;
}

td.autoship-action button {
  margin: 0 8px;
  padding: 0 !important;
  vertical-align: top;
  font-size: 12px;
  line-height: 0;
  height: 23px;
  width: 57px;
}

td.action-btn button {
  font-size: 12px;
  height: 26px;
  margin: 0 10px;
}

table.commission-table td {
  text-align: center;
}

table.commission-table th {
  text-align: center;
}

.cart-product-details select {
  width: 100%;
  min-width: 120px;
  max-width: 140px;
}

.Add_to_cart.abc button {
  height: 45px;
  padding: 0;
  width: 130px;
}


button.disable {
  background: #aaa7a7 !important;
  border: 2px solid #aaa7a7 !important;
  color: #fff;
}

button.disable:hover {
  background: none !important;
  color: #aaa7a7 !important;
}

/* new */

.row.addres-page input {
  width: 100%;
  height: 41px;
  padding-left: 10px;
}

.select {
  border-radius: 30px;
  border: 1px solid #ccc;
  overflow: hidden;
}

.select select {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  border: none;
}

.row.addres-page input {
  width: 100%;
  height: 41px;
  padding-left: 10px;
  border-color: #ddd;
}



.Toal-Sale strong {
  padding-right: 10px;
  float: left;
  text-align: left;
}

.Toal-commission strong {
  padding-right: 10px;
  float: left;
}

.top-head-commission {
  border: 2px solid #000;
  padding: 10px 0;
  display: flex;
}

.left-commison-sec span {
  padding-right: 10px;
}

.left-commison-sec {
  float: left;
  text-align: left;
}

.left-commison-sec span {
  padding-right: 10px;
}

.order_table th {
  width: 16%;
}

button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.css-d0ukip-MuiButtonBase-root-MuiButton-root {
  background: var(--green);
  color: #fff;
  float: right;
}

.Cart_product.order-detail-sec th {
  padding: 12px;
  font-family: var(--common-fontmd);
}

button.re-order {
  margin-top: 16px;
}

.mainorder-detail-sec .container.order-detail-page button {
  margin-bottom: 0px;
  text-transform: capitalize;
  margin-bottom: 0;
  transition: 0.3s ease all;
  padding: 10px 25px;
  border-width: 2px !important;
  font-size: 14px;
  margin-top: 0px;
  white-space: nowrap
}

.mainorder-detail-sec .container.order-detail-page .main_order_detail_class button.add_new_card {
  margin: 0 0 20px 20px;
}

.comistion-table-data table td:last-child {
  display: table-cell !important;
}

.row.not-active {
  background: #33c2eb3b;
}

.dt-jned span {
  font-weight: 600;
}

.active-block p.check:after {
  position: absolute;
  content: "\F00C";
  font-family: FontAwesome;
  left: 0;
}

.active-block p.not-check:after {
  position: absolute;
  font-family: FontAwesome;
  left: 0;
  content: "\F00D";
}

.buttton-reem .btn {
  margin-bottom: 35px;
}

.left-profile-data label {
  width: 160px;
  margin-bottom: 20px;
}

.pro-fil-user .btn {
  width: 110px;
  height: 43px;
}

.orer-details-tables {
  border: 1px solid #ddd;
  margin: 0 0 25px;
  width: 100%;
}

.orer-details-tables table {
  width: 100%;
}

.orer-details-tables table th {
  border-bottom: 1px solid #ddd;
}

.orer-details-tables td {
  border-bottom: 1px solid #ddd;
  padding: 14px;
  border-right: 1px solid #ddd;
}

.orer-details-tables th {
  border-right: 1px solid #ddd;
}

.orer-details-tables th:last-child {
  border-right: 0;
}

.orer-details-tables td:last-child {
  border-right: 0;
}

.update-details .form-group {
  display: flex;
  margin-bottom: 5px;
}

.update-details label {
  width: 209px;
}

.update-details span {
  position: absolute;

  left: 0;
  right: 0;
  margin: 35px auto;
  text-align: center;
}

.update-details input {
  margin-bottom: 14px;
}

.update-details span {
  position: absolute;
  left: 0;
  left: 0;
  right: 0;
  margin: 43px auto 0;
  text-align: center;
}

.right-side .form-group {
  display: flex;
}

.right-side label {
  width: 203px;
}

.wallet_data {
  float: right;
  width: 100%;
  text-align: right;
}

.buttton-reem {
  float: right;
}

.details_price {
  text-align: right;
}

/*Now the for li tree CSS*/

.tree ul {
  padding-top: 20px;
  position: relative;
  width: max-content;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::after,
.tree li::before {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child {
  padding-top: 0;
}

/*Remove left connector from first child and 
right connector from last child*/
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

.tree li .boxshape {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li .boxshape:hover,
.tree li .boxshape:hover+ul li .boxshape {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}

/*Connector styles on hover*/
.tree li .boxshape:hover+ul li::after,
.tree li .boxshape:hover+ul li::before,
.tree li .boxshape:hover+ul ul::before,
.tree li .boxshape:hover+ul::before {
  border-color: #94a0b4;
}

ul.showing-node.hide {
  display: none;
}

/* end li box */
input[type="button"] {
  padding: 0 10px;
}

.mainorder-detail-sec.commision-now .Custom_filter {
  max-width: 86%;
  flex: 0 0 86%;
}

form.Create-order-form input {
  height: 34px;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 20px;
  font-size: 11px !important;
  vertical-align: top;
}

span.error_create {
  color: red;
  position: absolute;
  bottom: -2px;
  font-size: 13px;
}

.form-group {
  position: relative;
}

.total_value {
  margin-bottom: 16px;
}

button.add-product-autoshiporder {
  margin: 15px 0;
  background: var(--green);
  border: none;
  color: #fff;
  padding: 7px 25px;
  border-radius: 5px;
}

button.add-product-autoshiporder {
  margin: 15px 0;
  background: var(--green);
  border: none;
  color: #fff;
  padding: 7px 60px;
  border-radius: 5px;
}

/* button {
  background: var(--green);
  border: none;
  color: #fff;
  padding: 7px 25px;
  border-radius: 5px;
} */

.Create-order-form button {
  background: var(--green);
  border: none;
  color: #fff;
  padding: 7px 25px;
  border-radius: 5px;
}


.total_value strong {
  width: 108px;
  float: left;
}

span.sizer {
  display: none;
}

.search.selection.dropdown input[type="text"] {
  display: none;
  width: 50%;
  height: 45px;
  padding: 0 15px;
  border: 1px solid #cac2c2;
  margin: 15px 0 0;
  border-radius: 3px;
}

.Create-order-form button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator.css-osgkwv-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
  background: none !important;
  color: #000 !important;
  padding: 0 !important;
}

button.add-product-autoshiporder {
  padding: 7px 60px !important;
}

.total_value strong {
  width: 138px;
}

form.Create-order-form .form-group {
  margin-bottom: 30px;
}

span.error_create {
  color: red;
  position: absolute;
  bottom: 7px;
  font-size: 13px;
}

form.Create-order-form input.search {
  width: 41% !important;
  height: 48px !important;
  padding-left: 10px !important;
}



/* horizntal */
*,
*:after,
*:before {
  box-sizing: border-box;
}


#wrapper {
  position: relative;
}

.branch {
  position: relative;
  margin-left: 250px;
}

.branch:before {
  content: "";
  width: 50px;
  border-top: 2px solid #eee9dc;
  position: absolute;
  left: -100px;
  top: 50%;
  margin-top: 1px;
}

.entry {
  position: relative;
  min-height: 60px;
}

.entry:before {
  content: "";
  height: 100%;
  border-left: 2px solid #eee9dc;
  position: absolute;
  left: -50px;
}

.entry:after {
  content: "";
  width: 50px;
  border-top: 2px solid #eee9dc;
  position: absolute;
  left: -50px;
  top: 50%;
  margin-top: 1px;
}

.entry:first-child:before {
  width: 10px;
  height: 50%;
  top: 50%;
  margin-top: 2px;
  border-radius: 10px 0 0 0;
}

.entry:first-child:after {
  height: 10px;
  border-radius: 10px 0 0 0;
}

.entry:last-child:before {
  width: 10px;
  height: 50%;
  border-radius: 0 0 0 10px;
}

.entry:last-child:after {
  height: 10px;
  border-top: none;
  border-bottom: 2px solid #eee9dc;
  border-radius: 0 0 0 10px;
  margin-top: -9px;
}

.entry.sole:before {
  display: none;
}

.entry.sole:after {
  width: 50px;
  height: 0;
  margin-top: 1px;
  border-radius: 0;
}

.label {
  display: block;
  min-width: 150px;
  padding: 5px 10px;
  line-height: 20px;
  text-align: center;
  border: 2px solid #eee9dc;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -15px;
}

/* end */


input.product-qty {
  border: none;
}

span#quick-notifications-menu {
  width: 20px !important;
  height: 20px !important;
  border-radius: 20px;
  min-width: 20px !important;
}

.entry {
  min-height: 202px;
}

.branch {
  margin-left: 307px;
}

.wallet_data {
  float: none !important;
  text-align: left;
}

.Kaire_cash {
  display: flex;
}

.show-email-check,
.show-phone-check {
  width: 100%;
  position: absolute;
  display: flex;
  top: 39px;
  left: 152px;
  right: 0;
  text-align: center;
  margin: 0 auto;
}



.update-details input[type="checkbox"] {
  height: 20px;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: none !important;
  box-shadow: none !important;
}

input.form-control:focus {
  box-shadow: none !important;
}

.wallet_data ul {
  display: flex;
}

.wallet_data ul li {
  display: inline-block;
}

.wallet_data ul {
  display: flex;
  margin-left: 0 !important;
  padding-left: 0;
}

.wallet_data button {
  margin: 0 10px;
  float: right;
}

.box-inner {
  display: flex;
}

.kaire_cash_ul li:nth-child(2) {
  width: 11%;
}


.box-inner {
  max-width: 60%;
  flex: 0 0 60%;
  display: flex;
}

.groupicon-dd {
  display: flex;
  flex-wrap: wrap;

}

.groupicon-dd .box-inner {
  max-width: 100%;
  flex: 0 0 100%;
}

.kaire_cash_ul div {
  padding-left: 10px;
}

.commission_cash_ul div {
  padding-left: 10px;
}

.clickable {
  cursor: pointer;
}

.mainorder-detail-sec.commision-now .Custom_filter {
  max-width: 83%;
  flex: 0 0 83%;
}

.mainorder-detail-sec.commision-now .Custom_filter,
.mainorder-detail-sec.refund-report.section .Custom_filter {
  flex: 0 0 76%;
  max-width: 76%;
}

.comission-data-table {
  overflow-y: auto;
  width: 100%;
}

span.name_d0 {
  font-size: 13px;
  line-height: 19px;
  display: block;
}

span.date_data {
  font-size: 13px;
}

.entry {
  min-height: 245px;
}

.branch:before {
  content: "";
  width: 72px;
  border-top: 2px solid #eee9dc;
  position: absolute;
  left: -122px;
  top: 50%;
  margin-top: 1px;
}

/* 
span.label.lable-translate {
  animation-name: fade-in-top;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}

.lavel-box .animations .box {
  animation-name: fade-in-top;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.branch.hide {
  /* -webkit-animation: fadeinout 1s linear forwards; */
/* animation: fadeinout 1s;
}

@-webkit-keyframes fadeinout {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

 branch.hide {
  opacity: 0;
} */

/* .branch {
  opacity: 1;
  animation-name: fade-in-top;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
} */



.react-calendar__tile--active {
  background: #006edc !important;
  color: white;
}

.react-calendar button {
  background: #f8f9fa;
  border: none;
  color: #413636;
}

.react-calendar__tile {
  max-width: 100% !important;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}

.highlight {
  background: rgb(14 174 90 / 25%) !important;
}

.react-calendar__tile--active {
  background: #006edc !important;
  color: white !important;
}

.tooltip1 {
  position: relative;
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 13px;
  position: fixed;
  z-index: 1;
}

.highlight:hover .tooltiptext {
  visibility: visible;
}

/* ens this section */

/* suetome date commision */
.custom-date-collection {
  position: absolute;
  top: 52px;
  left: 0;
}

.groups.tabs.commission-tab {
  position: relative;
  z-index: 0;
}

input#standard-required {
  border: none;
}

.fromdateshow {
  position: absolute;
  left: 0;
  z-index: 999999;
  top: 86px;
}

.tildate {
  position: absolute;
  left: 227px;
  z-index: 999999;
  top: 86px;
}

.react-calendar {
  position: relative;
  z-index: 999999;
}

.transactions-data-table table {
  border: 1px solid #ddd;
}

.transactions-data-table table td {
  text-align: left;
}

.transactions-data-table table th {
  padding: 5px;
  background: #f9f9f9;
  text-align: left;
  padding-left: 14px;
  font-size: 15px;
  font-weight: 600;
}

.comission-data-table .commission-table {
  overflow-y: auto;
  width: 100%;
  position: unset;
  z-index: -1;
}

.get_commision button {
  padding: 1px 19px;
}

.clickableicon {
  cursor: pointer;
}

.details-list-section.hide,
.details-list-section1.hide {
  display: none;
}

.box-expand-btn {
  position: absolute;
  left: 187px;
  top: 31px;
}

.box .first_name {
  cursor: pointer;
}

.icon-name {
  cursor: pointer;
}

span.label.lable-translate .box-expand-btn {
  position: absolute;
  left: 155px;
  top: 33px;
}

/* end customdate */

ul.kaire_cash_ul {
  float: right;
}

.wallet_data {
  display: block !important;
  width: 100% !important;
  float: right !important;
  text-align: right !important;
}

ul.commission_cash_ul {
  float: right;
  width: 100%;
}

.wallet_data ul {
  display: block;
}

table.commission-table th {
  position: relative;
  z-index: 0;
}

.dashboard-table-commsion tr.MuiTableRow-root.main.css-1q1u3t4-MuiTableRow-root td {
  /* padding-left: 0; */
  text-align: left;
}

.dashboard-table-commsion tr.MuiTableRow-root.MuiTableRow-head.css-1q1u3t4-MuiTableRow-root th {
  text-align: left;
  /* padding-left: 0; */
}



.comistion-table-data.dashboard-table-commsion td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-1ex1afd-MuiTableCell-root {
  padding: 0 !important;
}

/* table.MuiTable-root.inner-table.css-rqglhn-MuiTable-root td:first-child {
  width: 225px;
} */

table.MuiTable-root.inner-table.css-rqglhn-MuiTable-root td {
  text-align: left;
}

.image-item {
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

/* design downline finel */
.groups.tabs .defaultshow-down {
  display: flex;
  width: 100%;
}

.groups.tabs .icon-count {
  display: flex;
}

.groups.tabs .label {
  min-width: 235px;
  padding: 5px;
}

.groups.tabs .icon img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  margin-right: 10px;
}

.branch:before {
  left: -75px;
}

span.label.lable-translate .icon {
  margin-right: 10px;
}

.groups.tabs .icon img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  margin-right: 10px;
}

span.label.lable-translate .icon {
  margin-right: 10px;
}

span.name_d0 {
  line-height: 15px;
}

.icon-name {
  font-weight: 600;
  padding: 3px 0 0;
}



.groups.tabs .label {
  padding: 0;
}

.icon-count span {
  background: #ddd;
  position: absolute;
  right: 0;
  height: 25px;
  width: 36px;
  padding-top: 3px;
}



.groups.tabs .icon img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  margin-right: 10px;
  padding: 2px;
}

span.name_d0 {
  line-height: 19px;
  font-size: 12px;
}

span.label.lable-translate .icon {
  margin-right: 10px;

  height: 25px;
  padding: 2px;
}



span.name_d0 svg {
  margin-top: 2px !important;
}

.icon-name {
  max-width: 100%;
  flex: 0 0 100%;
  float: left;
  text-align: left;
}

span.label.lable-translate .icon {
  margin-right: 0;
  height: 25px;
  padding: 2px;
}

.box svg {
  width: 10px !important;
  margin: 0;
}



.entry:before {
  height: 100%;
  border-left: 1px dashed #eee9dc;
}

.entry:after {
  width: 50px;
  border-top: 1px dashed #eee9dc;
  top: 50%;
}

.entry:last-child:after {
  height: 10px;
  border-top: none;
  border-bottom: 1px dashed #eee9dc;
  border-radius: 0 0 0 10px;
  margin-top: -9px;
}

.branch:before {
  border-top: 1px dashed #eee9dc;
}


.icon-name {
  width: 158px;
  font-weight: 600;
  padding: 3px 0 0;
  max-width: inherit;
  flex: initial;
}

.icon-count .name_d0 {
  background: #ddd;
  width: 38px;
  justify-content: center;
}

.groups.tabs .label {
  min-width: 220px;
  padding: 5px;
}

.icon-count svg {
  margin-right: 9px;
  margin-top: 4px;
}

span.name_d0 svg {
  margin-top: 4px !important;
}

span.counts_user-d {
  position: absolute;
  right: 5px;
  top: 1px;
}

.groups.tabs .icon img {
  width: 21px;
  height: 21px;
  object-fit: cover;
  margin-right: 10px;
  padding: 2px;
}

.icon svg {
  width: 13px !important;
  height: 19px !important;
}

.groups.tabs .label {
  min-width: 220px;
  padding: 0;
}

.icon-name {
  width: 100%;
}

span.counts_user-d {
  position: absolute;
  right: 5px;
  top: 1px;
  background: none;
  width: auto;
  padding-top: 0;
}

span.name_d0 svg {
  margin-top: 4px !important;
  width: 29px !important;
}

span.counts_user-d {
  position: absolute;
  right: 5px;
  top: 4px;
  background: none;
  width: auto;
  padding-top: 0;
}

span.counts_user-d {
  position: absolute;
  right: 5px;
  top: 4px;
  background: none;
  width: auto;
  padding-top: 0;
  font-size: 12px;
}

span.name_d0 svg {
  margin-top: 3px !important;
  width: 29px !important;
}

span.name_d0 svg {
  margin-top: 0 !important;
  width: 29px !important;
}

.groups.tabs .label {
  min-width: 220px;
  padding: 0;
  border: none;
}

.box {
  border: 1px solid #ddd;
  border-radius: 3px;
}


.details-list-section {
  border: 1px solid #ddd;
  margin: 10px 0;
  border-radius: 0px;
  box-shadow: 0 0 10px #ddd;
}

.hovershow {
  display: flex;
  padding: 0 10px;
}

.hovershow .icons svg {
  width: 12px;
}

.details-list-section .data {
  padding: 0 10px;
}

span.date_title {
  font-size: 13px;
  padding-right: 3px;
  padding-left: 0;
}

span.date_title svg {
  margin-right: 6px;
}

span.total_order_title {
  font-size: 13px;
}

span.sale_title {
  font-size: 13px;
}

span.total_order_title svg {
  margin-right: 8px;
}

span.sale_title svg {
  margin-right: 8px;
}

.details-list-section {
  border: 1px solid #ddd;
  margin: 10px 0;
  border-radius: 3px;
  box-shadow: 0 0 10px #ddd;
  height: 93px;
}

span.date_data strong {
  font-size: 11px;
}

span.total_order_data strong {
  font-size: 11px;
}

span.sale_data strong {
  font-size: 11px;
}

.entry.firstlevel:before {
  display: none;
}

.branch:before {
  left: -84px;
}

.entry.firstlevel:after {
  display: none;
}

.icon svg {
  width: 27px !important;
  height: 19px !important;
}

.icons a svg {
  width: 9px;
  margin-right: 4px;
}

.user_rank h3 {
  font-size: 13px;
  width: 174px;
  float: left;
  text-align: left;
}

.branch.lv1.level0::before {
  display: none;
}

.entry {
  min-height: 42px;
}

/* end */

/* horizontal view */
.tree li .boxshape {
  border: none;
}

.box {
  border: none;
}

.tree li .boxshape:hover,
.tree li .boxshape:hover+ul li .boxshape {
  background: transparent;
  color: #000;
  border: 1px solid transparent;
}


.tree {
  width: 2486px;
}

.show_on_hover {
  box-shadow: 0 0 10px #ddd;
  width: 251px;
  height: 193px;
  padding: 15px;
  border-radius: 4px;
}

.tree_top_details h3 {
  font-size: 15px !important;
  font-weight: 600;
  color: #000;
}

.data {
  display: flex;
  float: left;
  width: 100%;
  margin-bottom: 0;
  margin-top: 10px;
  align-items: center;
}


.data strong,
.data {
  font-size: 13px;
}

.show_on_hover {
  box-shadow: 0 0 10px #ddd;
  width: 250px;
  height: 150px;
}

span.count-val {
  background: #000;
  width: 28px;
  height: 28px;
  padding: 6px;
  color: #fff;
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tree li .boxshape:hover,
.tree li .boxshape:hover+ul li .boxshape {
  background: transparent;
  color: #000;
  border: none;
}




.boxshape:hover .default_info+.show_on_hover {
  visibility: visible;
}

.profile-img-d {
  height: 100px;
  overflow: hidden;
}

.profile-img-d img {
  object-position: center;
}

/* end */

/* new design implementataion */

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  border: none;
}

.header-section .nav li a {
  color: #000;
  text-decoration: none;
}

/* .header-section .navbar {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0px 15px;
  margin-bottom: 0;
} */

/* .navbar-brand {
  float: left;
  height: 50px;
  padding: 0 15px;
} */

/* .header-section ul.nav.navbar-nav.navbar-left {
  padding-left: 20px;
} */

/* .header-section .nav>li>a {
  padding-right: 10px;
  padding-left: 10px;
} */

/* body .container {
  width: 1672px;
} */

.image-welcome img {
  width: 100%;
}

.welcome-section {
  padding: 46px 0;
}

.welcome-text h4 {
  font-size: 45px;
  color: #000;
  font-family: var(--common-fontbd);
  margin-bottom: 0;
}

.welcome-text p {
  font-size: 17px;
  color: #000;
  font-weight: 500;
  line-height: 28px;
  padding-top: 10px;
}

.text-welcome h2 {
  margin: -82px 0 0;
  text-align: right;
  font-size: 60px;
  color: #fff;
  font-weight: normal;
  font-family: var(--common-fontbd);
}

.par-text {
  width: 1234px;
  margin: 103px auto;
}

.par-text p {
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  font-family: var(--common-fontbd);
}

.welcome-text.maritime-text h4 {
  text-align: center;
}

.welcome-text.maritime-text {
  padding: 30px 42px;
  width: 515px;
  transform: translate(-113px, 96px);
}

.product-main {
  text-align: center;
}

.product-main img {
  width: 100%;
  margin: 10px 0;
}

a.btn.btn-learn {
  background: #00356a;
  border-radius: 25px;
  color: #fff;
  padding: 5px 20px;
  display: inline-flex;
  justify-content: center;
  height: 40px;
  max-width: 150px;
  font-size: 15px;
}

.lean-main {
  text-align: center;
  /* margin-top: -16px; */
  margin-bottom: 20px;
}

.fotter-list li:last-child a {
  border-right: none;
}

.fotter-listdetail li a {
  font-size: 14px;
}

.fotter-listdetail li {
  padding: 0 50px;
}

span.loc1 {
  display: block;
  padding: 10px 0;
}

.question-text {
  margin-top: -60px;
  margin-bottom: 60px;
}

.question-text h1 {
  font-size: 99px;
  color: #fff;
  margin: 0 0 150px;
  padding: 0 95px;
  font-weight: bold;
}

.stay-touch-part {
  max-width: 918px;
  margin: 0 auto;
}

.form-subscribe {
  max-width: 945px;
  margin: 0 auto;
}

.form-subscribe .form-group {
  width: 100%;
}

form.form-inline {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

input#staticEmail {
  border: none;
  background: none;
  margin: 0 0 0 0px;
  border-bottom: 1px solid #000;
  width: 100%;
  font-size: 17px;
}

.form-subscribe .btn {
  background: #000;
  border: none;
  border-radius: 25px;
  width: 123px;
  height: 38px;
  font-size: 17px;
  margin-top: 10px;
}

/* Previous button  */
.media-carousel .carousel-control.left {
  left: -12px;
  background-image: none;
  background: none repeat scroll 0 0 #00356a;
  border: none;
  border-radius: 0;
  height: 40px;
  width: 40px;
  margin-top: 30px;
  top: 33%;
  opacity: 1;
  padding-top: 0;
  font-size: 28px !important;
}

/* Next button  */
.media-carousel .carousel-control.right {
  right: -12px !important;
  background-image: none;
  background: none repeat scroll 0 0 #00356a;
  border: none;
  border-radius: 0;
  height: 40px;
  width: 40px;
  margin-top: 30px;
  top: 33%;
  opacity: 1;
  padding-top: 0;
  font-size: 28px !important;
}

/* Changes the position of the indicators */
.media-carousel .carousel-indicators {
  right: 50%;
  top: auto;
  bottom: 0;
  margin-right: -19px;
}

/* Changes the colour of the indicators */
.media-carousel .carousel-indicators li {
  background: #c0c0c0;
}

.media-carousel .carousel-indicators .active {
  background: #333333;
}

/* End carousel */
.product-main .thumbnail {
  background: transparent;
  border: none;
  margin-bottom: 0;
  text-decoration: none;
}

.product-main img {
  max-width: 180px;
  margin: 0 auto;
  height: 230px;
  object-fit: fill;
}

.media-carousel {
  margin-bottom: 0;
  padding: 0 80px 30px;
  margin-top: 30px;
}

.product-main {
  background: #fff;
  text-align: center;
  justify-content: center;
  margin: 0 20px;
  padding: 30px 0 10px;
}

.product-main h5 {
  font-family: var(--common-fontbd);
  color: #000;
}

.home-page>.slider-main {
  margin: 150px 0 100px;
}

.welcome-section.pycn-section {
  position: relative;
  padding: 0;
  /* z-index: 999999999999; */
}

.product-main1 {
  text-align: center;
  margin: 30px 0 11px;
}

.btn-shop-all {
  text-align: center;
}

.btn-shop-all a {
  text-align: center;
  background: #00356a;
  border: 2px solid #00356a;
  border-radius: 25px;
  width: 100%;
  height: 50px;
  font-size: 17px;
  color: #fff;
  font-family: var(--common-font);
  align-items: center;
  line-height: normal;
  transition: 0.3s ease all;
  display: inline-flex;
}

.btn {
  max-width: 240px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-family: var(--common-font);
  align-items: center;
  transition: 0.3s ease all;
  line-height: normal;
}

.dashboard_div a {
  display: flex;
}



li.logout_icon svg {
  color: #fff !important;
  margin: 0 0 0;
}

li.notifications svg {
  color: #fff !important;
  margin-top: 25px;
}

.icon-box-cart {
  position: relative;
}

.icon-box-cart span {
  background: var(--green);
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  display: flex;
  position: absolute;
  top: -12px;
  left: 0;
  padding-left: 0;
  font-size: 14px !important;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: var(--common-fontmd);
}

ul.dropdown-menu-for-cart li {
  cursor: pointer;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  text-align: left;
}

ul.dropdown-menu-for-cart {
  background: #fff;
  width: 250px;
  height: 250px;
  overflow-y: scroll;
  position: absolute;
  right: -31px;
  z-index: 99;
  padding: 0;
  border-top: 4px solid var(--green);
}

li.notifications:hover .dropdown-menu-for-cart {
  display: block;
  z-index: 99999;
}

ul.dropdown-menu-for-cart {
  display: none;
}

ul.dropdown-popup_viewCart img {
  width: 30px;
}

ul.dropdown-popup_viewCart li {
  display: flex;
}

ul.dropdown-popup_viewCart .txt {
  color: #000;
}

ul.dropdown-popup_viewCart {
  background: #fff;
}

ul.dropdown-popup_viewCart li a {
  background: var(--green);
  width: 100%;
  text-align: center;
  padding: 0;
  line-height: 1px;
  margin-bottom: 0;
  height: 47px;
  margin-top: 16px;
}

ul.dropdown-popup_viewCart .txt {
  color: #000;
  padding-left: 15px;
}

ul.dropdown-popup_viewCart {
  background: #fff;
  width: 300px;
  padding: 10px;
}

ul.dropdown-popup_viewCart {
  display: none;
}

ul.dropdown-menu-wishlist {
  display: none;
}

li.notifications span {
  background: red;
  border-radius: 50%;
  width: 19px !important;
  height: 19px !important;
  display: block;
  position: absolute;
  padding-left: 4px;
  border: 2px solid #fff;
  line-height: 14px;
  font-size: 11px;
  top: 20px;
  left: 10px;
}

span#quick-notifications-menu {
  width: 9px !important;
  height: 23px !important;
  min-width: 23px !important;
  line-height: 9px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}



.cart span,
.icon-box span {
  background: #fff;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  display: block;
  color: #000;
  position: absolute;
  top: -12px;
  left: 3px;
  padding-left: 7px;
  line-height: 21px;
  font-size: 10px;
  font-family: var(--common-fontmd);
}

li.view_cart_section:hover .dropdown-popup_viewCart {
  display: block;
}

/* 
li.wishlist_section:hover .dropdown-menu-wishlist {
  display: block;
  position: absolute;
  left: -144px;
  z-index: 99;
} */

ul.dropdown-popup_viewCart img {
  width: 41px;
}

ul.dropdown-menu-for-cart li {
  padding: 10px;
}

ul.dropdown-menu-for-cart li:hover {
  background: var(--green);
  color: #fff;
}

.login_signin {
  display: flex;
  align-items: center;
}

.welcome-text {
  background: #fff;
  padding: 39px 49px;
  width: 422px;
  min-height: 370px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  text-align: center;
}


.welcome-text.maritime-text {
  padding: 13px 26px;
  width: 620px;
  transform: translate(-113px, 57px);
}

.par-text {
  width: 1234px;
  margin: 91px auto 24px;
}

.welcome-text.maritime-text h4 {
  font-size: 40px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0;
  width: 319px;
  margin: 7px auto;
}

.product-main1 img {
  width: 300px;
}

.lean-main {
  text-align: center;
  /* margin-top: -27px; */
}

.welcome-text.maritime-text {
  padding: 13px 26px;
  width: 570px;
  transform: translate(-113px, 81px);
}

div#media {
  width: 1470px !important;
  margin: 0 auto;
  padding: 0 15px;
}

.btn-shop-all {
  margin-top: 37px;
}

.question-text h1 {
  font-size: 80px;
}

.form-subscribe label {
  font-size: 48px;
  font-family: var(--common-fontbd);
  font-weight: normal;
}

.form-subscribe {
  max-width: 100%;
  margin: 0;
  text-align: center;
}

.stay-touch-part h5 {
  font-weight: 300;
  position: absolute;
  top: 145px;
  margin-left: 0px;
  font-size: 24px;
}

.form-subscribe .btn {
  margin-left: 0px;
}

.question-text {
  margin-bottom: 100px;
}


.silck-slide-home .slick-slide {
  padding: 0 20px;
}

.product-main {
  margin: 0 20px;
}

div#media {
  width: 1380px !important;
  margin: 0;
  padding: 0;
}

.silck-slide-home .button_pre {
  left: 0;
}

.button_next {
  right: 0;
}

.silck-slide-home .button_next,
.silck-slide-home .button_pre {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--green);
  z-index: 999;
  border-radius: 5px;
  color: #fff;
  font-size: 11px;
}

.silck-slide-home .slick-slider.slick-initialized {
  width: 1212px;
  margin: 0 auto 0 68px;
  justify-content: center;
}

.product-main h5 {
  font-size: 20px;
  margin: 9px 0 0px;
  text-transform: uppercase;
}

.product-main>svg {
  font-size: 40px;
  color: #bbbbbb;
}

.product-main:hover svg {
  color: var(--green);
}

.carousel img {
  width: 200px !important;
  display: inline-block;
  pointer-events: none;
  height: 200px !important;
  object-fit: cover;
}

.mainorder-detail-sec.commision-now .Custom_filter {
  max-width: 86%;
  flex: 0 0 86%;
}

/* .fotter_section .fotter-list ul li {
  list-style: none;
} */

.lavel-box .row {
  display: flex;
  width: 100%;
  clear: both;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm .get_commision {
  padding: 3px 0 !important;
  height: 37px;
  line-height: 32px;
  white-space: nowrap;
}

button.btn:focus {
  outline: none;
}

.add_address_class_from_autoship,
.add_address_from_CheckoutPage,
.change_passsword_from_profile,
.wishlist_popup_page,
.view_cart_popup {
  min-width: 695px !important;
}

.product_add_from_autoship {
  margin: 0 auto;
  max-width: 750px !important;
}

.comistion-table-data.dashboard-table-commsion td {
  font-size: 12px;
}

.error {
  font-size: 12px;
  color: #f00;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.tooltip_main_class {
  font-size: 12px !important;
}

button.btn.btn-larn {
  background: #00356a;
  border: 2px solid #00356a;
  border-radius: 25px;
  width: 100%;
  height: 40px;
  max-width: 140px;
  font-size: 17px;
  color: #fff;
  margin-top: 18px;
  transition: 0.3s ease background;
}

button.btn.btn-larn:hover {
  background: #fff;
  color: #00356a;
}

.container_of_slider {
  display: none;
}

.pie_cahrt_pop {
  width: 220px;
}

.tooltip_section {
  display: flex;
  gap: 4px;
}

.pie_cahrt_pop p {
  font-size: 14px;
  margin-bottom: 0;
  text-align: center;
}

.tooltip_section {
  justify-content: center;
}

.pie_cahrt_pop .mouseover_class {
  font-size: 12px;
}

form.signupform.main-sign-frm input#standard-required {
  padding: 10.5px 14px;
  font-size: 14px;
}

/* pooja 27Oct */
.stay-touch-part h5 {
  top: 0;
  margin-left: 87px;
  position: unset;
  margin-bottom: 0;
}

.dashboard_div .logout_icon.from_navBar_section {
  background: rgb(255, 255, 255);
  position: absolute;
  width: 100%;
  right: 0px;
  border-radius: 0px;
  color: rgb(0, 0, 0);
  padding: 0px;
  display: flex;
  visibility: hidden;
  font-size: 12px;
  -webkit-box-pack: center;
  justify-content: center;
}

.dashboard_div:hover .logout_icon.from_navBar_section {
  visibility: visible;
}

.container.order-detail-page>h2 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
}

li.logout_icon.from_navBar_section svg,
:where(.account_icon,
  .icon-box-cart) svg {
  color: var(--site-purple);
  margin-left: 5px;
}

/* .main_saved_card_class .row.saved-nmain {
  display: none !important;
} */

@media (min-width: 768px) {
  .order_table.address-list.order-list.auto-ship-order-table.smartship {
    overflow-x: auto;
  }
}

@media (min-width: 767px) {
  .silck-slide-home button[type="button"] {
    display: none;
  }
}


@media (max-width: 991px) {
  /* .header-section {
    background-size: 100%;
  } */

  /* .container.order-detail-page.order-history .order_table.address-list.order-list table.commission-table td.action-btn {
    padding-top: 15px !important;
  } */

}

/* 28-oct-2021 */
/* .product-slider .thumb-slider .slick-vertical .slick-slide {
  height: 141px !important;
  margin-top: 0 !important;
}

.product-slider-now .slick-slider img {
  height: 550px !important;
}

.product-slider .thumb-slider .slick-list {
  padding-top: 0 !important;
}

.study-now {
  transform: translateY(35%) !important;
} */

/* 23-nov-2021 */
.Toastify__toast-container--top-right {
  top: 6em;
}

/* animation svg background */
.animated-gradients.position-fixed {
  height: 100%;
  overflow: hidden;
  width: 100%;
  z-index: -1;
  top: 0;
  position: fixed !important;
  display: none;
}

/* .animated-gradients.position-fixed svg {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
} */

.welcome-section.gauratee-section .welcome-text {
  text-align: center;
  padding: 5px 10px 20px;
  width: 380px;
  transform: translate(32px, 55px);
}

.welcome-section.gauratee-section p {
  padding-top: 10px;
  width: 100%;
  max-width: 348px;
  margin: 0 auto;
}

.welcome-section.gauratee-section {
  padding: 92px 0 5px;
}

.welcome-section.gauratee-section .welcome-text p {
  font-size: 15px;
  color: #000;
}

.autoship_price_for_true,
.allproduct_autoship {
  color: #000;
  font-size: 16px;
  padding-right: 0;
  align-items: center;
  display: flex;

}

.autoship_price_for_true svg {
  width: 23px;
  height: 23px;
  border-radius: 30px;
  border: 1px solid var(--green);
  padding: 3px;
  margin-left: 5px;
  background: #c9d9ff;
  color: var(--green);
}

/* 27-12-2021 */


/* .navbar-toggle {
  display: none !important;
} */


/* 19-01-2022 */
.mini-cart-sec .checkout-btn {
  background: #fff;
}

.mini-cart-sec .checkout-btn ul {
  margin-top: 10px;
}

.form-subscribe .btn {
  width: 100%;
  max-width: 125px;
  flex: 125px;
  margin-right: 0;
  border: 2px solid #000;
}

.form-subscribe .btn:hover {
  background-color: #fff;
  color: #000;
}

.question-text .stay-touch-part {
  margin-left: 65px;
}

.form-subscribe .form-inline .form-group {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  width: 100%;
  max-width: calc(100% - 250px);
  flex: calc(100% - 250px);
}

.form-subscribe label {
  margin-bottom: 0;
  line-height: normal;
}

.form-subscribe .form-inline .form-group label.col-form-label {
  max-width: 260px;
  flex: 260px;
}

.form-subscribe .form-inline .form-group input#staticEmail {
  max-width: calc(100% - 350px);
  flex: calc(100% - 350px);
}

.dt-jned {
  text-align: right;
}

.current-rank-left,
.current-rank-right {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main_dashboard_section_class .dt-jned span,
.orer-details-tables table tr th:first-child {
  text-align: left;
}

.row.order-cstm table.table.inner-table-shop td {
  text-align: revert;
}

.MuiCollapse-vertical .MuiBox-root td.MuiTableCell-root {
  border-bottom: 0;
}

.total_sale .Toal-commission+.Toal-commission {
  margin-top: 8px;
  position: relative;
}

.Toal-commission span {
  align-items: center;
  display: flex;
  position: absolute;
  right: 10px;
}

.dashboard_div,
.icon-box-cart {
  position: relative;
}

.upper_lower_div {
  justify-content: flex-end;
}

ul.nav.navbar-nav.navbar-top li {
  float: none;
  justify-content: flex-end;
}

.upper_lower_div ul.navbar-top li:first-child a:last-child {
  padding-bottom: 0;
  padding-right: 8px;
}

.upper_lower_div ul.navbar-top li:first-child a {
  text-decoration: none;
}

.upper_lower_div ul.navbar-top li span {
  color: #fff;
  padding-top: 0;
  line-height: normal;
  margin-top: 0px;
}

.view_cart_popup {
  max-width: 1020px !important;
  max-height: calc(100% - 64px);
  width: 100%;
}

/* 24-03-2022 */
.welcome-text h4 strong {
  font-family: var(--common-font);
  font-weight: 300;
}

.silck-slide-home .button_next,
.silck-slide-home .button_pre,
a.btn.btn-learn {
  transition: 0.3s ease all;
  border: 2px solid var(--green);
}

.silck-slide-home .button_next:hover,
.silck-slide-home .button_pre:hover,
.btn-shop-all a:hover,
a.btn.btn-learn:hover {
  background: #fff;
  color: var(--green);
}

.reply_chat_section_main_dialog {
  background: #021a35;
}

.reply_chat_section_main_dialog>div {
  padding: 0;
}

.customPackPopup {
  max-width: 1080px !important;
}

.customPackPopup .container_for_load_data {
  max-height: 600px;
}

.radio-bnt {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

/* 
.radio-bnt:after {
  content: "";
  width: 40px;
  height: 40px;
  background: #e9f3fd;
  position: absolute;
  right: -6px;
  top: -6px;
  border-radius: 50%;
  transition: 1s ease-in-out all;
  z-index: -1;
}

.radio-bnt:hover:after {
  transform: scale(40);
} */


.apply-coupn form.signupform input {
  border: none;
  box-shadow: none;
  height: 100%;
  min-height: 45px;
}

.apply-coupn form.signupform {
  border-radius: 40px;
  border: 1px solid #ddd;
  overflow: hidden;
}

.apply-coupn,
.kairecassh {
  background: #fff;
}

table.Cart_product {
  background: #fff;
  border-radius: 0px;
  overflow: hidden;
}

/* 15-04-2022 */
.main_dashboard_section_class .dt-jned span:last-child {
  max-width: 20px;
  flex: 1;
  text-align: right;
}

.main_dashboard_section_class .dt-jned span:nth-child(2) {
  text-align: right;
  flex: 1;
  max-width: inherit;
  word-break: break-all;
  /* padding-right: 10px; */
}

.main_dashboard_section_class .dt-jned {
  align-items: center;
}

.deatis-info .dt-jned span:first-child {
  max-width: 190px;
  text-align: left;
}

.current-part+br {
  display: none;
}

/* 18-05-2022 */
.groups.tabs ul.tabs {
  max-width: calc(100% - 40px);
  margin: 0 auto;
  float: none;
  display: flex;
  padding: 0;
}

.Custom_filter .MuiFormControl-root input,
.Custom_filter .MuiFormControl-root * {
  font-size: 15px;
  color: #000;
  text-transform: capitalize;
  font-weight: 500;
  margin: 0;
}

.Custom_filter .MuiFormControl-root input {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.Custom_filter .MuiInput-root:before {
  display: none;
}

button.MuiButtonBase-root {
  font-size: 16px;
}

.css-karnbp .MuiTypography-root.MuiTypography-caption {
  font-size: 16px;
  font-weight: 600;
  color: #00356b;
}

.css-8v7him .css-9a6yxo {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.css-1lomthf button span {
  font-size: 14px;
  text-transform: capitalize;
  color: #000;
  margin: 0;
}

.PrivatePickersToolbar-root>span.MuiTypography-root {
  color: #000;
  font-size: 11px;
}

.Custom_filter .MuiFormControl-root label {
  padding: 0 5px;
  bottom: 30px;
  top: auto;
  left: 10px;
  z-index: 1;
  background: #fff;
}

.MuiDialogActions-root button.MuiButton-text.MuiButton-textPrimary {
  background: #00356b;
  border-radius: 30px;
  font-size: 14px;
  padding: 7px 20px;
  color: #fff;
  font-family: 'Lato';
  min-width: 90px;
  border: 2px solid #00356b;
  font-weight: 600;
  margin: 0;
}

.MuiDialogActions-root button.MuiButton-text.MuiButton-textPrimary:hover {
  background: #fff !important;
  color: #00356b;
}

.Custom_filter .MuiFormControl-root .MuiInput-root:after {
  display: none;
}

.MuiPaper-root.MuiPaper-elevation .MuiDialogActions-root {
  gap: 10px;
}

table.table.inner-table-shop {
  margin-bottom: 0;
}

.order-detail-page.main_address_page_listing .button-top {
  margin: 0;
}

.order-detail-page.main_address_page_listing .button-top .button.add-address {
  display: flex;
}

.mainorder-detail-sec .container.order-detail-page .autoship-action button {
  border-radius: 0;
  background: none;
  border: none;
  width: auto;
}

.edit_address button {
  width: auto;
}

table.Cart_product.whistlist-table tr:last-child td {
  padding-top: 15px;
}

.top-head-commission>div {
  letter-spacing: -0.2px;
  font-size: 20px;
}

.row.cupon_and_kiare_section .coupam-enter {
  padding: 0;
}

.row.cupon_and_kiare_section .apply-coupn {
  margin: 0;
  padding: 20px;
  height: auto;
}

.row.cupon_and_kiare_section .apply-coupn .col-md-12 {
  padding: 0;
}

.row.cupon_and_kiare_section .copanerror-cls {
  color: #ff0000;
  font-size: 16px;
}

/* 01-06-2022 */
.Loader::before {
  content: '';
  float: left;
  padding-top: 100%;
}

.Loader__Circle {
  display: block;
  position: absolute;
  border-radius: 50%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.8;
  mix-blend-mode: screen;
  filter: brightness(120%);
  -webkit-animation-name: SpinAround;
  animation-name: SpinAround;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear
}

div#careers>.medium-margin {
  margin-bottom: 0;
}

.Loader.loader-main {
  width: 200px;
  min-width: 110px;
  overflow: visible;
  margin: 20px;
  padding: 10px;
  border-radius: 50%;
  box-shadow: inset 0 0 8px rgb(255 255 255 / 40%), 0 0 25px rgb(255 255 255 / 80%);
  z-index: 99;
  position: relative;
  background: url('/images/logo_snip.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 120px;
  border: 1px solid #000;
  filter: invert(1);
}

.loader-back {
  position: fixed;
  z-index: 9999;
  background: var(--site-purple);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}

.Loader::after {
  content: attr(data-text);
  color: #000;
  position: absolute;
  left: 50%;
  top: calc(100% + 20px);
  transform: translate(-50%, -50%);
  font-size: calc(70% + 0.10vw);
  text-transform: uppercase;
  letter-spacing: 5px;
}

span.name_d0 {
  justify-content: flex-end;
}

.icon-name span.name_d0 svg:first-child {
  opacity: 0;
}

.MuiModal-root {
  z-index: 9999 !important;
}

.orer-details-tables th {
  background: #ddd;
}

/* 01-07-2022 */
.statis_datas .commsion-weekly-dashboard .dashboard-table-commsion table.inner-table tbody tr:not(:last-child) td {
  border-bottom: 0;
}

.container.order-detail-page .groups.tabs ul.tabs {
  max-width: calc(100% - 67px);
}

.saved-new-card img {
  max-width: 60px;
  object-fit: contain;
  height: 30px;
}

.Level .istviewsection {
  padding-top: 10px;
}

/* .Level {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
} */

.Level .row:last-child {
  padding-bottom: 10px;
}


/* 04-07-2022 */
[class*="Downlinestyle__Downline"] {
  float: left;
  width: 100%;
}


.flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: fixed;
  z-index: 999;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  left: 50%;
  justify-content: center;
}

.flexbox>div {
  width: 300px;
  height: 300px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.bt-spinner {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: transparent;
  border: 4px solid #f5f5f5;
  border-top-color: var(--green);
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}


.flexbox:before {
  content: "";
  width: 100%;
  height: 100vh;
  background: rgb(255 255 255 / 50%);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body.bg-salmon [class*="NavBarStyle-sc"] {
  visibility: hidden;
  opacity: 0;
}

/* 
.bg-salmon .fotter_section {
  display: none;
} */


@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.table_container {
  border: 1px solid #ddd;
  border-radius: 30px;
  overflow-x: hidden;
  margin-bottom: 30px;
}

table.Cart_product.whistlist-table {
  width: 100%;
  max-width: 100%;
  text-align: left;
  margin-bottom: 0;
}

table.Cart_product.whistlist-table tr:not(:last-child) td {
  border-bottom: none
}

table.Cart_product.whistlist-table td:not(:last-child) {
  border-right: none
}


table.Cart_product.whistlist-table tr td {
  padding: 15px 20px;
}

table.Cart_product.whistlist-table th {
  text-align: left !important;
}

.bg-footer {
  overflow: hidden;
}


/* 18-08-2022 */
.thnku-sec .Cart_product .table-wrap+.row {
  margin-top: 10px;
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.container.checkout-main.checkout_main_section_class :where(h4, strong) {
  font-family: var(--common-fontmd);
  font-size: 20px;
}

.main_checkout_autoship_main_dialog .check_out_main p {
  font-size: 16px;
  line-height: 1.5;
}

.thnku-sec .Cart_product {
  margin-bottom: 50px;
}

.container.order-detail-page .groups.tabs ul.tabs .col-md-6 button {
  margin: 0;
}

.mainorder-detail-sec .container.order-detail-page .react-share__ShareButton {
  padding: 0 !important;
}

.autoship_order_detail .autoship_details_table .table-responsive .qty-sec #qty {
  min-width: auto;
}

.autoship_order_detail .autoship_details_table .table-responsive .qty-sec #qty :where(.sub, .add) {
  padding: 0 !important;
  width: 35px !important;
  max-width: 35px !important;
}







/* 21-07-2022 */

.banner-video {
  position: relative;
  height: 650px;
  overflow: hidden;
  float: left;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 70px;
}

.banner-video iframe {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 130vw;
  height: 160%;
}

.add_address_from_CheckoutPage_main_dialog .form-check {
  display: flex;
  align-items: center;
}

.add_address_from_CheckoutPage_main_dialog .form-check input[type="checkbox"] {
  height: auto;
  line-height: normal;
  margin-top: 0;
}

.add_address_from_CheckoutPage_main_dialog .row {
  margin: 0;
}


.add_address_from_CheckoutPage_main_dialog .select:after {
  top: 20px;
}

.add_address_from_CheckoutPage_main_dialog .form-check {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}


.add_address_from_CheckoutPage_main_dialog p.form-check-label {
  margin-bottom: 5px;
}

.order_table.address-list.auto-ship-order-table a {
  color: #fff;
  background: var(--green);
  padding: 10px 15px;
  border-radius: 30px;
  border: 2px solid var(--green);
  text-decoration: none;
  margin: 0 auto;
  transition: 0.3s ease all;
}

.order_table.address-list.auto-ship-order-table a:hover {
  background: #fff;
  color: vvar(--green);
}

.show_on_hover .tree_top_details .icons a {
  color: #fff;
}

.show_on_hover .tree_top_details .icons {
  display: flex;
  justify-content: flex-end;

}

.show_on_hover .tree_top_details {
  background: var(--green);
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.show_on_hover .data svg {
  color: var(--green);
  fill: var(--green);
}

.show_on_hover .data .icons {
  line-height: 0;
}

.signupandforget {
  margin-top: 20px;
  display: flex;
  width: 100%;
}

form.signupform.main-sign-frm button.btn.btn-primary.sigup {
  background: none;
  color: var(--green);
  text-transform: uppercase;
  border: none;
  padding: 0;
  text-align: left;
  max-width: initial;
}

.signupandforget>* {
  flex: 1;
  align-items: center;
}

.signupandforget .forgot.password {
  padding: 0;
  text-align: right;
}

.thn-lft h3 {
  margin: 7px 0 0;
  font-size: 16px;
  font-family: var(--common-fontmd);
}

.thn-lft .row {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  column-gap: 20px;
}

.thn-lft .row::before {
  display: none;
}

.thn-lft .row>[class*=col-md] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border-radius: 0;
  max-width: calc(100%/2 - 10px);
  width: 100%;
  border-right: 1px solid #ddd;
}

.thn-lft .row>[class*=col-md]:nth-child(-n + 2) {
  border-top: 1px solid #ddd;
}

.thn-lft h3 {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.thn-lft .row>[class*=col-md]>div,
.thn-lft h3 {
  margin: 0;
  padding: 15px;
  max-width: 50%;
  border-bottom: 1px solid #ddd;
  flex: 50%;
  line-height: normal;
  min-height: 60px;
  align-items: center;
  display: grid;
}

.thn-lft h4 {
  background: var(--green);
  padding: 10px 18px;
  color: #fff;
  border-radius: 5px;
  margin: 0px 0 20px;
}

.thn-lft {
  position: relative;
  overflow: hidden;
  z-index: 9;
}


.mian-thank h2 {
  width: 100%;
}

.mian-thank svg.bi.bi-check-circle {
  width: 90px;
  height: 90px;
  fill: #27a200;
}

.mian-thank {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.container-main h3 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
}

.comistion-table-data.dashboard-table-commsion table.MuiTable-root.inner-table.css-rqglhn-MuiTable-root {
  background: #eaf2ff;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.css-1ygcj2i-MuiTableCell-root:empty,
.MuiTableRow-root td.MuiTableCell-root.MuiTableCell-body:not(.MuiCollapse-vertical td.MuiTableCell-root.MuiTableCell-body):nth-child(1):empty {
  display: none;
}

.MuiCollapse-wrapperInner.MuiCollapse-vertical [aria-label="purchases"] tr.MuiTableRow-root td {
  padding-left: 28px;
}

.MuiCollapse-wrapperInner.MuiCollapse-vertical [aria-label="purchases"] tr.MuiTableRow-root td:nth-child(3) {
  padding-left: 38px;
}

.Kosher a {
  display: flex;
  max-width: 100px;
  justify-content: center;
  margin: 10px auto 0;
  color: #000;
}

.Kosher a img {
  width: 100%;
  max-width: 60px;
  height: 60px;
}

a.par-logo span {
  width: 25px;
  height: 25px;
  background: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  font-weight: 900;
  margin: 0 auto 5px;
}

.Kosher a.par-logo p {
  font-size: 14px;
}

.slider-main .content-product>svg {
  font-size: 40px;
  color: #ccc;
}

.product-main:hover .content-product>svg {
  color: var(--green);
}

.product-main:hover .content-product {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.content-product {
  transform: translateY(-100px);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease all;
  transform-origin: top;
}

.table-wrap table th {
  background: var(--green);
  color: rgb(255, 255, 255);
  text-align: left;
  padding: 20px;
  font-size: 16px;
  text-transform: capitalize;
  font-family: var(--common-fontmd);
  font-weight: normal;
}

.table-wrap+.row {
  margin-top: 10px;
  border: none;
}

.table-wrap table {
  width: 100%;
  border: 1px solid #eaeaea;
}

.table-wrap tr.productDetailTable:nth-child(2n) {
  background: #f5f5f5;
}

.table-wrap tr.productDetailTable td {
  padding: 20px;
  font-size: 15px;
}

.cart-product-name {
  font-weight: 700;
}

[aria-label="Ships now & after 28 days"] {
  background: var(--green) !important;
  color: #fff !important;
}

.crossImg .close_button {
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
}

.crossImg .close_button svg.MuiSvgIcon-root {
  color: var(--green);
  width: 25px;
  font-size: 18px;
  height: 25px;
}

/* 09-08-2022 */
.order_details_main_dialog h3 {
  background: var(--green);
  color: #fff;
  padding: 10px;
  font-size: 22px;
}

.order_details_main_dialog .close_button {
  right: 10px;
  padding: 0;
  top: 18px;
}

.check_out_main .check_out {
  margin: 40px 0 20px;
}

.check_out_main .check_out,
.check_out_main .check_out-btn {
  text-align: center;
}

.undefined_main_dialog .check_out-btn button {
  background: none;
  color: var(--green);
}

.check_out_main button {
  font-size: 16px;
  color: #fff;
  border-radius: 30px;
  padding: 10px 20px;
}

/* 16-08-2022 */
.groups.tabs .transactions-data-table table.MuiTable-root button.MuiButtonBase-root {
  padding: 8px 15px !important;
}

/* .order-history table.commission-table th:last-child {
  width: 270px;
  max-width: initial;
} */

body .mainorder-detail-sec .container.order-detail-page button {
  font-size: 14px;
  height: 36px;
  width: 100%;
  margin: 0 10px 10px;
  min-width: max-content;
  align-items: center;
  justify-content: center;
  line-height: 0;
  padding: 7px 28px;
  font-weight: normal;
  font-family: var(--common-font);
  max-width: max-content;
  text-transform: none;
}

.main_order_section_class table .title12 {
  width: 100% !important;
}

.icon-box-cart svg {
  margin-top: 2px;
}


.main_div {
  /* padding-bottom: 430px; */
  min-height: calc(100vh - 563px);
}


.fotter_section {
  width: 100vw;
}

/* body {
  position: relative;
  min-height: 100vh;
} */

.order_table.address-list.order-list tbody tr td .edit_address button {
  padding: 0 !important;
  margin: 0 10px !important;
  font-size: 15px;
}

.main-invitaion table.comistion-table-data.dashboard-table-commsion.address-list thead th.year_block {
  padding: 15px 10px;
}

.not-check+.not-check span.eye_icon_main_class {
  right: 0;
}



/* 04-10-2022 */

form.signupform.main-sign-frm h1 {
  font-size: 23px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-family: var(--inte);
}

.logo img {
  width: 100%;
  max-width: 150px;
  margin-left: 0;
  margin-right: auto;
}

.logo {
  margin-bottom: 20px;
  text-align: right;
}

.banner-product-newimage img {
  max-width: 200px;
}

.banner-product-newimage {
  position: absolute;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
}

.paymentform_from_savedCardComp_main_dialog h2.dialog_title_for_all {
  font-size: 20px;
  margin-bottom: 20px;
}

/* for custom scroll */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #38528e;
}



/* 28-10-2022 */
div#__next {
  background-image: linear-gradient(90deg, #e0e4e6 0%, #c0cbd1 100%);
  overflow: hidden;
}

.left-img,
.bottom-img {
  position: absolute;
  left: 0;
  bottom: 90px;
}

body h2 {
  font-size: 43px;
  line-height: 49px;
  color: var(--green);
  font-family: var(--inte-BD);
  margin-top: 0;
}

.right-img {
  position: absolute;
  z-index: 0;
  top: 70px;
  right: 0;
}

button.dlt {
  color: red;
}

.left-img {
  top: 110px;
  bottom: auto;
}

.d-flex {
  display: flex;
}

body {
  position: relative;
}

.fifth_section_icons {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 50px 0 20px;
  align-items: center;
  gap: 15px;
}

.fifth_section_icons img {
  width: 100%;
  max-width: 172px;
}

.small_text {
  text-align: center;
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
}

.small_text p {
  margin-bottom: 0;
}

body .container {
  max-width: 1440px;
  width: 100%;
  position: relative;
  z-index: 1;
}


.right_side_imageDesign {
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 0;
}

.left_side_imageDesign {
  position: absolute;
  left: 0;
  z-index: 0;
  top: 0;
}

.right_side_imageDesign img,
.left_side_imageDesign img {
  z-index: -1;
  position: relative;
}

.main_background_customer_info .right_side_imageDesign img,
.main_background_customer_info .left_side_imageDesign img {
  z-index: -1;
  position: relative;
  opacity: 0.5;
}

button {
  border: none;
  color: #fff;
  padding: 7px 25px;
  font-family: var(--exo);
}

body {
  overflow-x: hidden;
  position: relative;
}

.mini-cart-sec {
  position: fixed;
  right: 0;
  top: 0;
  background: #ffff;
  max-width: 376px;
  box-shadow: 0 0 10px 1px #ddd;
  height: calc(100vh);
  width: 100%;
}

.mini-cart-sec .container {
  padding: 0;
  background: #fff;
  height: 100%;
}

.mini-cart-sec .container h1.title {
  background: linear-gradient(90deg, rgb(251, 215, 9) 0%, rgb(233, 79, 36) 100%);
  color: #fff;
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 0;
}

.mini-cart-sec .container .min-cat-uppper {
  display: flex;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  padding: 10px 15px 20px;
  position: relative;
  align-items: center;
}

.mini-cart-sec .container .mini-cart .cart-image {
  width: 30%;
}

.mini-cart-sec .container .mini-cart .mini-cart-des {
  width: 70%;
  float: left;
  padding-left: 10px;
  text-align: left;
}

.mini-cart-sec .container button.btn.btn-primary {
  margin: 0 auto;
  display: table;
  float: none;
}

.mini-cart {
  position: relative;
}

.mini-cart-sc {
  overflow-y: auto;
  height: calc(100vh - 240px);
}

.mini-cart-sec .container .mini-cart .cart-image img {
  width: 100%;
}

.mini-cart-sec .container .mini-cart .cart-product-qty-del {
  position: absolute;
  right: 0;
  top: 0;
  /* left: 0;
  margin: 0 auto;
  text-align: center; */
}

.mini-cart-sec .container .mini-cart .cart-product-qty-del button.dlt {
  background: transparent;
  color: #000;
  font-size: 13px;
  width: 100%;
  height: auto;
}

.mini-cart-sec .cart-heading {
  margin-bottom: 0;
  margin-top: 0;
}

.mini-cart-sec .container .mini-cart .mini-cart-des .cart-product-details {
  font-weight: 600;
  margin-bottom: 6px;
  font-size: 15px;
}

.mini-cart-sec .container .mini-cart .mini-cart-des .main-qty-sec {
  justify-content: flex-start;
  margin-bottom: 5px;
  margin-left: 0;
}

.mini-cart-sec .container .mini-cart .mini-cart-des .main-qty-sec button {
  color: var(--green);
  border-radius: 0;
  background: #fff;
  padding: 10px;
  width: 50px;
  height: 40px;
  font-weight: 800;
  border: 1px solid var(--green);
}

.mini-cart-sec .container .mini-cart:nth-child(2n) {
  background: #ececec;
}

.mini-cart-sec .checkout-btn {
  width: 100%;
  float: left;
  position: absolute;
  padding: 0 15px;
  bottom: 0;
  left: 0;
}

.mini-cart-sec .checkout-btn .row {
  margin: 0;
}

.mini-cart-sec .checkout-btn .row ul {
  width: 100%;
}

.mini-root-div {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(90deg, rgb(251, 215, 9) 0%, rgb(233, 79, 36) 100%);
  padding: 13px;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
}

.mini-root-div .fa.fa-shopping-cart {
  color: #fff;
  font-size: 24px;
}

.mini-root-div span {
  position: absolute;
  top: 8px;
  right: 4px;
  background: #f00;
  color: #fff;
  font-size: 14px;
  width: 17px;
  height: 17px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.container .col-md-9.dashboard-main {
  margin: auto;
  width: 75%;
  padding: 10px;
  text-align: center;
}

.date-picker-sign input[type="date"] {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.field-class.radio-select .MuiFormGroup-root {
  position: relative;
  flex-direction: unset;
  align-items: center;
}

.verify-ref .d-flex label {
  width: 100%;
}


.verify-ref .d-flex label {
  width: 100%;
}

.verify-ref .d-flex button {
  background: var(--green);
  border-radius: 30px;
  width: 100px;
  right: 1px;
  border: 1px solid #ddd;
  height: 38px;
  top: 1px;
  gap: 8px;
  padding: 10px;
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  justify-content: center;
}

.verify-ref .d-flex button svg {
  fill: #fff;
}

.verify-ref {
  width: 100%;
  max-width: 100%;
  margin: 0px 0 20px;
}

.verify-ref+.field-class+.field-class label {
  opacity: 0;
}

.wrap-field {
  position: relative;
  width: 100%;
  max-width: 460px;
}

.verify-ref .d-flex button span {
  color: #fff;
  font-weight: 600;
  font-family: var(--common-font);
  text-transform: uppercase;
}

.field-class.radio-select .MuiFormGroup-root span.MuiRadio-root {
  position: relative;
  padding: 0;
  display: block;
  width: auto;
  height: auto;
}

.field-class.radio-select label.MuiFormControlLabel-root {
  margin-left: 0;
}

.field-class.radio-select input.PrivateSwitchBase-input {
  position: relative;
  height: auto !important;
}

.field-class.radio-select span:empty {
  display: none;
}

.mb-3.field-class.radio-select span.MuiRadio-root {
  width: 100%;
  max-width: 100%;
}

.field-class.radio-select span[class*="css-1i"] {
  width: 30px;
  color: var(--green);
  height: auto;
}

.field-class.radio-select input.PrivateSwitchBase-input.css-mraihx {
  display: none;
}

.field-class.radio-select .MuiFormGroup-root span.MuiTypography-root {
  color: var(--green);
  font-size: 14px;
}

.field-class.radio-select label.MuiFormControlLabel-root {
  align-items: flex-start;
}

.field-class.radio-select span[class*="css-1i"] svg {
  font-size: 20px;
}

.field-class.radio-select .MuiFormGroup-root label.MuiFormControlLabel-root {
  margin-bottom: 0;
}

.main_sign_up_form_class .signupform.main-sign-frm .mb-3.field-class.radio-select {
  margin-bottom: 0 !important;
  padding-top: 10px;
  padding-bottom: 10px !important;
}

.field-class.radio-select .MuiFormGroup-root label.MuiFormControlLabel-root:last-child {
  margin: 0;
}

span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
  width: fit-content;
  min-width: fit-content;
}

/* .field-class.radio-select .MuiRadio-root svg.MuiSvgIcon-root {
  right: 15px !important;
  top: 5px !important;
} */



tr.not-found {
  display: none;
}

.main-cstm {
  padding: 0 25px;
}

.main-cstm h2 {
  padding: 10px 15px;
  color: #fff;
  line-height: normal;
  font-size: 20px;
  margin: 0;
  box-shadow: 6px 6px 17px -12px #3a3232;
  background: var(--site-purple);
  font-family: var(--inte);
}

.note-mainbx {
  padding: 10px 20px 17px;
  background: #f1f1f1;
  border-radius: 5px;
  margin-bottom: 15px;

}

.saved_card_section:empty {
  display: none;
}

body.professional_retailer_form_popup {
  overflow: hidden;
}

body.professional_retailer_form_popup:before {
  content: "";
  background: #00000085;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
}

.cart-product-details {
  padding-right: 15px;
}

.order-wrapper .mb-3.field-class.radio-select svg.MuiSvgIcon-root[data-testid="RadioButtonUncheckedIcon"] {
  left: 0;
  top: 5px;
  color: var(--green);
}

.order-wrapper .mb-3.field-class .wrap-field svg {
  position: absolute;
  right: 10px;
  top: 12px;
  color: var(--green);
}

.order-kit .order-wrapper form.signupform.main-sign-frm.form-kit {
  max-width: 400px;
  background: linear-gradient(180deg, #e40685 0%, #bd1b86 21%, #632e8a 100%);
  -webkit-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b691e6", endColorstr="#6e8bde", GradientType=1);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b691e6", endColorstr="#6e8bde", GradientType=1);
  border-radius: 25px;
  overflow: hidden;
  padding: 25px;
  margin: 0 auto;
  z-index: 1;
  position: relative;
}

.Sample-instructions a.DNA.Sample-instructions {
  color: #fff;
}

.Sample-instructions {
  margin-top: 5px;
}

.order-kit .order-wrapper form.signupform.main-sign-frm.form-kit input.form-control {
  height: 40px;
  box-shadow: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 30px;
  font-family: var(--common-font);
  display: flex;
  align-items: center;
  border: 0;
  box-shadow: unset;
  border-radius: 9px;
  height: 51px;
  padding: 10px;
}

.order-kit .order-wrapper form.signupform.main-sign-frm.form-kit h5 {
  color: #fff;
  text-align: center;
  font-size: 29px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: var(--common-fontbd);
}

.order-kit .order-wrapper form.signupform.main-sign-frm.form-kit .go_to_next button {
  background: var(--green);
  margin-right: 8px;
  border-radius: 30px;
  max-width: 130px;
  height: 40px;
  color: #000;
  text-transform: uppercase;
  border: 2px solid #fff;
  margin-top: 20px;
}

/* 07-11-2022 */
.order-detail-page.order-history .title12 {
  display: flex;
  /* justify-content: flex-start; */
  justify-content: center;
}

.order-detail-page.order-history td.action-btn {
  width: 100%;
  min-width: 350px;
}

.order-detail-page.order-history td.action-btn .title12 button {
  margin-right: 0;
}

.become-affiliate {
  padding: 220px 0 80px;
}

.common-heading,
.affiliates-earn {
  text-align: center;
}

.common-heading h3 {
  font-size: 33px;
  margin: 0 0 10px;
  font-family: var(--common-fontmd);
}

.common-heading p {
  font-size: 27px;
}

.become-affiliate .container {
  max-width: 1170px;
}

.common-heading img {
  width: 100%;
  max-width: 100%;
}

.common-heading span {
  width: 100%;
  display: block;
}

.btn-affiliate button.btn-primary-btn {
  border-radius: 30px;
  background: var(--green);
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  font-family: var(--exo);
  text-align: center;
}


.Scenario-2.common-heading {
  padding: 40px 41px;
}

.btn-affiliate {
  padding: 50px 0 80px;
}

.product-sales.common-heading p {
  margin: 0px;
}

.product-sales.common-heading {
  max-width: 880px;
  margin: 0 auto;
}

.produccts-share h2 {
  text-align: center;
  margin-bottom: 90px;
}

.product-sales.common-heading p.income-text {
  font-size: 21px;
}

.Scenario-2.common-heading p {
  margin-bottom: 40px;
}

.Scenario-1.common-heading {
  margin-top: 20px;
}

.affiliates-earn.common-heading h3,
.Scenario-1.common-heading h3 {
  margin-bottom: 15px;
}

h2.dialog_title_for_all img {
  margin: 0 auto 0 0;
  filter: invert(1);
  max-width: 70px;
}

.circle_section span.MuiCircularProgress-root {
  width: 100% !important;
  max-width: 100%;
  color: #fff;
  height: 100% !important;
}



.circle_section .MuiTypography-root span {
  font-size: 24px;
  font-weight: 300;
  color: #fff;
}

.circle_section .MuiTypography-root circle.MuiCircularProgress-circle.MuiCircularProgress-circleDeterminate {
  stroke-width: 1px !important;
}

/* 08-11-2022 */
table.Cart_product.whistlist-table h3.title {
  margin: 0;
  padding: 10px;
}

.Cart_product.order-detail-sec.refund_detail_section.cstm-refund table.commission-table td.qty-sec .main-qty-sec {
  max-width: max-content;
  margin: 0 auto;
}

.logout_mobile {
  display: none !important;
}

.Cart_product.order-detail-sec.refund_detail_section.cstm-refund table.commission-table td.qty-sec .main-qty-sec div#qty button {
  border-radius: 0;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  line-height: normal;
  min-height: auto;
  height: auto;
  display: flex;
  align-items: center;
}

.main_refund_section_class {
  padding-left: 30px;
  padding-right: 30px;
}

.Cart_product.order-detail-sec.refund_detail_section.cstm-refund table.commission-table td.qty-sec .main-qty-sec div#qty input.product-qty {
  text-align: center;
  font-size: 18px;
  font-family: var(--common-fomntmd);
}

.new_york_popup_main_dialog .MuiDialogContent-root.container_for_load_data {
  padding-top: 15px;
  font-size: 18px;
  text-align: center;
}

.MuiDialogContent-root.container_for_load_data p a {
  color: var(--green);
}

.new_york_popup_main_dialog h2 {
  font-family: var(--inte);
  font-weight: 900;
}

.verify-ref .d-flex {
  flex-wrap: wrap;
}


.form-wrap [name="referral_code"] {
  padding-right: 30px;
  padding-left: 120px;
}

.verify-ref .d-flex input.form-control {
  max-width: 460px;
}

.MuiCircularProgress-svg circle {
  stroke-width: 1px;
}

.form-wrap .content {
  position: absolute;
  left: 10px;
  top: 8px;
}

.mb-3.field-class.radio-select {
  display: flex;
  gap: 10px;
  padding: 0 0 15px !important;
}

.mb-3.field-class.radio-select label {
  line-height: normal;
  min-height: auto;
  height: auto;
  margin-bottom: 0;
}

.main_div [class*="kit_form__Kit"] {
  padding: 260px 0 140px;
}

.success.page {
  max-width: 900px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  background: #fff;
  padding: 50px;
  border-radius: 10px;
}

.success.page:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
}

.success.page p {
  margin-bottom: 0;
  font-size: 20px;
}

.success.page p span {
  display: block;
  font-size: 26px;
  margin-bottom: 10px;
  color: #008117;
  text-transform: uppercase;
}

.success.page i {
  font-size: 50px;
  color: #fff;
  background: green;
  border-radius: 50%;
  padding: 10px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -100px auto 30px;
  border: 8px solid #d0d6da;
}

/* 16-11-2022 */
.input_field label {
  font-weight: normal
}

a.unlock {
  color: var(--site-purple);
  font-family: var(--common-fontmd);
  transition: 0.3s ease all;
  position: relative;
  margin-right: 5px;
  text-decoration: none;
}

a.unlock:before {
  content: "";
  position: absolute;
  left: -5px;
  bottom: calc(100% - 33px);
  background: var(--site-purple);
  height: 2px;
  width: 103%;
  transition: 0.3s ease all;
  z-index: -1;
}

a.unlock:hover:before {
  height: 100%;
}

a.unlock:hover {
  color: #fff;
  text-decoration: none;
}

:is(.add_to_cart, .button_sections, .first_container p) button {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

:is(.add_to_cart, .button_sections, .first_container p) button:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  transform: translateX(-100%);
  background: linear-gradient(90deg, #fbd709 0%, #e94f24 100%);
  position: absolute;
  z-index: -1;
  left: 0;
  transition: 0.3s ease all;
}

:is(.add_to_cart, .button_sections, .first_container p) button:hover:before {
  transform: translateX(0);
}

.update-details.main-profile {
  margin-bottom: 10px;
}

.groups.tabs ul.tabs {
  margin-left: 0;
}

.mb-3.field-class.date-picker-sign {
  position: relative;
}

.mb-3.field-class.date-picker-sign label {
  position: absolute;
  left: 5px;
  top: 10px;
  padding: 0 5px 0 10px;
  background: #fff;
  margin: 0;
}

.mb-3.field-class.date-picker-sign input[type="date"] {
  padding-left: 70px;
  width: 100%;
}

form.signupform.main-sign-frm .Custom_filter .date_range_filter select {
  color: #000;
}

.display_youtube_main_dialog {
  background: #000;
}

.display_youtube_main_dialog h2 {
  margin: 0 !important;
  background: #000 !important;
  padding: 3px 0 0 0 !important;
}

.display_youtube_main_dialog .container_for_load_data {
  padding: 0;
}

.display_youtube_main_dialog h2 .close_button svg {
  font-size: 16px !important;
}

.display_youtube.MuiDialog-paper {
  width: 100%;
  max-width: 700px !important;
}

.youtube_video {
  margin: 0;
}

.Cart_product tr td span.only-rsponsive {
  display: none;
}

.Cart_product tr td.radio_section_div h2.title {
  display: none;
}

@media (max-width: 1280px) {


  .bottom-img img {
    max-width: 300px;
  }

  .small_text {
    margin-bottom: 40px;
  }

  .small_text .my-3 {
    margin-bottom: 20px;
  }

  .fifth_section_icons {
    margin: 40px 0;
  }

  body h2 {
    font-size: 36px;
  }

  .left-img,
  .bottom-img,
  .right-img {
    opacity: 0.3;
  }
}

@media (max-width: 1199px) {
  .logout_mobile {
    display: block !important;
  }
}

@media (max-width: 1024px) {

  .Cart_product tr td span.only-rsponsive {
    display: none;
  }


  .left-img img,
  .right-img img {
    max-width: 360px;
  }

  .container.checkout-main h1.title,
  .container.order-detail-page>h2 {
    font-size: 28px;
  }
}

@media (max-width: 991px) {
  .Cart_product tr td span.only-rsponsive {
    display: none;
  }

  .fifth_section_icons {
    flex-wrap: wrap;
    max-width: 630px;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .container.checkout-main h1.title {
    margin-top: 0;
  }

  .produccts-share h2 {
    margin-bottom: 40px;
  }

  .common-heading p {
    font-size: 23px;
  }

  .Scenario-2.common-heading {
    padding: 40px 15px;
  }

  .common-heading h3 {
    font-size: 28px;
  }

  .btn-affiliate button.btn-primary-btn {
    font-size: 23px;
  }

  .btn-affiliate {
    padding: 40px 0 60px;
  }

  .produccts-share h2 {
    margin-bottom: 30px;
    font-size: 28px;
    line-height: normal;
  }

}

@media (max-width: 767px) {
  .become-affiliate {
    padding: 140px 0 50px;
  }

  .btn-affiliate button.btn-primary-btn {
    font-size: 18px;
  }

  .common-heading p {
    font-size: 20px;
    line-height: normal;
  }

  .Scenario-2.common-heading {
    padding: 30px 15px;
  }

  .common-heading h3 {
    font-size: 22px;
    margin-bottom: 0;
  }

  .btn-affiliate {
    padding: 30px 0;
  }

  .common-heading h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .Scenario-1.common-heading p {
    margin-bottom: 20px;
  }

  .Scenario-2.common-heading p {
    margin-bottom: 30px;
  }

  .produccts-share h2 {
    margin-bottom: 20px;
    font-size: 22px;
  }

  .right_side_imageDesign img,
  .left_side_imageDesign img,
  .left-img img,
  .right-img img {
    max-width: 150px;
    min-height: 1000px;
    object-fit: cover;
  }

  .mini-cart-sec {
    width: 100%;
  }

  .container.checkout-main h1.title,
  .container.order-detail-page>h2 {
    font-size: 24px;
    line-height: normal;
  }

  .cart-now-main table.Cart_product {
    min-width: 810px;
  }

  .cart-now-main {
    overflow-x: auto;
    margin-bottom: 20px;
  }

  .container.checkout-main form button {
    margin-bottom: 0;
  }

  .fifth_section_icons a img {
    max-width: 120px;
    margin: 0 auto;
  }

  .fifth_section_icons a {
    max-width: calc(100%/2 - 10px);
    flex: 100%;
    display: flex;
  }

  .small_text p {
    line-height: normal;
  }

  body h2 {
    font-size: 28px;
    line-height: normal;
  }

  .bottom-img img {
    max-width: 200px;
  }

  .cart-sec {
    padding: 106px 0 25px;
  }

  .main_sign_up_form_class .signupform.main-sign-frm .mb-3.field-class.radio-select {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  /* 07-11-2022 */
  table.Cart_product thead.row {
    display: none;
  }

  .Cart_product tbody tr td {
    position: relative;
  }

  /* .Cart_product tbody tr td:before {
    content: attr(data-value);
    background: var(--site-purple);
    color: #fff;
    width: 100%;
    padding: 10px;
    max-width: 140px;
    text-align: left;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
  } */

  .Cart_product tr td {
    position: relative;
    border: none;
    /* padding: 13px 10px 13px 145px !important; */
    padding: 13px 50px !important;
    display: flex;
    justify-content: center !important;
    flex-wrap: nowrap;
    /* justify-content: flex-end; */
    align-items: center;
    /* text-align: right; */
    word-break: break-word;
    width: 100%;
  }

  .Cart_product tr td.text_realted_css {
    flex-direction: column;
    gap: 10px
  }



  .Cart_product tbody tr {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100%;
    border: 1px solid #ddd;
  }

  .Cart_product tr td span.only-rsponsive {
    display: block;
  }

  .Cart_product tr td.radio_section_div h2.title {
    display: block;
  }

  .Cart_product tr td div.cart-product-qty-del {
    display: flex;
    align-items: center;
  }

  .Cart_product tr td div.cart-product-qty-del button {
    padding-left: 10px !important;
    padding-bottom: 4px;
  }

  .cart-now-main {
    overflow: visible;
    margin: 0;
  }

  .cart-now-main table.Cart_product {
    min-width: auto;
  }

  /* .Cart_product tr:last-child td::before,
  table.Cart_product.whistlist-table thead {
    display: none;
  }

  .Cart_product tr:last-child td {
    padding-left: 10px !important;
    justify-content: center;
  } */

  .container.order-detail-page h1 {
    font-size: 18px;
  }

  .add_address_class_from_autoship,
  .change_passsword_from_profile,
  .wishlist_popup_page,
  .view_cart_popup {
    min-width: 100% !important;
  }

  .add_address_from_CheckoutPage {
    min-width: 93% !important;
  }

  .header-section .nav li a {
    color: #fff;
  }

  .main_div [class*="kit_form__Kit"] {
    padding: 150px 0 80px;
  }

  .order-kit .order-wrapper form.signupform.main-sign-frm.form-kit h5 {
    font-size: 23px;
  }

  .mainorder-detail-sec .order-cstm .thn-lft {
    margin-bottom: 15px;
  }

  .mainorder-detail-sec .order-cstm .thn-lft h2 {
    text-align: center;
  }

  table tr.not-found,
  table.Cart_product.saved_card_table thead {
    display: none;
  }

  table.Cart_product.saved_card_table,
  .Table-container.table-responsive,
  table.Cart_product.saved_card_table .address-list .Cart_product {
    border: none
  }

  .container.order-detail-page h1 {
    font-size: 20px;
  }

  .main-cstm h2 {
    text-align: center;
  }

  .newrefshow.d-flex,
  .referral-copy {
    flex-wrap: wrap;
  }

  .referral-copy input[type="text"] {
    width: 100%;
  }

  .newrefshow.d-flex {
    position: relative;
  }

  .newrefshow.d-flex .copied_ref {
    position: absolute;
    right: 0;
    top: -14px;
  }

  .main-invitaion {
    overflow-x: auto;
  }

  .container.order-detail-page h1 {
    white-space: break-spaces;
  }

  .container.order-detail-page {
    padding-bottom: 15px !important;
  }

  .get_commision {
    margin: 10px 0 0 !important;
  }

  .container.order-detail-page .groups.tabs ul.tabs {
    padding-top: 20px;
  }

  form.signupform.main-sign-frm .Custom_filter .get_commision {
    margin: 20px 0 0 !important;
    text-align: center;
  }

  /* .container_for_load_data {
    padding: 0;
  } */

  .success.page {
    padding: 20px;
  }

  .mainorder-detail-sec .Cart_product.order-detail-sec .billing-arcstm .row {
    flex-wrap: wrap;
    gap: 0;
  }
}

.distributer_tooltip .MuiTooltip-tooltip {
  font-size: 10px;
}

.order_info_tooltip .MuiTooltip-tooltip {
  font-size: 10px;

}

/* 22-11-2022 */


.default_info+.show_on_hover {
  visibility: hidden;
}

.boxshape:hover .default_info+.show_on_hover {
  visibility: visible;
}

form.signupform.main-sign-frm .Custom_filter :where(.order_id_filter, .get_commision) {
  width: 100%;
  flex: 100%;
  max-width: max-content;
}

.order_id_filter {
  justify-content: center;
  gap: 5px;
}


@media(max-width: 767px) {
  .Custom_filter {
    justify-content: center;
  }

  .Custom_filter .date_range_filter {
    margin-bottom: 20px;
  }

  form.signupform.main-sign-frm .Custom_filter :where(.order_id_filter, .get_commision) {
    font-size: 16px;
  }

  td.radio_section_div {
    padding-left: 20px;
  }

  table.Cart_product thead {
    display: none;
  }
}

/* 06-12-2022 */

.Scenario-1.common-heading h3 {
  margin-bottom: 15px;
  color: #000;
  font-family: var(--inte-BD);
}

.Scenario-1 [class*="img-scenario"] {
  gap: 25px;
  max-width: calc(100%/3);
  width: 100%;
  flex: 1;
  justify-content: center;
}

.Scenario-1.common-heading.d-flex {
  gap: 30px;
}

.cotnainer-img {
  max-width: 250px;
}

.Scenario-1.common-heading.d-flex .cotnainer-left h3 {
  margin-bottom: 0;
  font-size: 60px;
  font-family: var(--common-fontbd);
}

.Scenario-1.common-heading h3 {
  line-height: normal;
}

.cotnainer-img span {
  font-size: 22px;
  color: #000;
  margin-top: 10px;
}

.cotnainer-left {
  display: grid;
  place-items: center;
  padding-bottom: 80px;
}

.common-heading {
  text-align: center;
  padding-bottom: 30px;
}

.Scenario-2.common-heading p {
  font-family: var(--common-fontmd);
}

.Scenario-2.common-heading {
  max-width: 860px;
  padding: 50px 15px 0;
  margin: 0 auto;
}

.paid-weekly p {
  text-align: center;
  color: #000;
}

.common-heading p strong {
  color: #000;
}

.produccts-share h2 {
  text-align: center;
  margin-bottom: 15px;
}

.Scenario-2.common-heading p {
  color: #000;
}

@media (max-width: 991px) {
  .Scenario-1.common-heading.d-flex .cotnainer-left h3 {
    font-size: 40px;
  }

  .cotnainer-img span {
    font-size: 16px;
  }

  .Scenario-1.common-heading h3 {
    font-size: 24px;
  }

  .btn-affiliate {
    padding: 0 0 40px;
  }

}

span.small-letter-span {
  /* font-size: 40px; */
  font-weight: 900;
  text-shadow: 0px 1px var(--green);
  -webkit-text-stroke: 1px var(--green);
}

@media (max-width: 767px) {
  .Scenario-1 [class*="img-scenario"] {
    max-width: 100%;
    gap: 30px;
    flex: 100%;
  }

  .Scenario-1.common-heading.d-flex {
    flex-wrap: wrap;
  }

  .btn-affiliate {
    padding: 0 0 20px;
  }
}

.popup-dashboard {
  border: unset;
  max-width: 100%;
  text-align: center;
}



button.btn.btn-primary.popup {
  color: #fff;
  background: var(--green);
  /* margin: 0 auto; */
  display: inline-flex;
  max-width: 150px;
  border-radius: 60px;
  border-color: var(--ggreen)
}

.popup-dashboard button:first-child {
  margin-right: 20px;
}

.reminder-save {
  font-size: 18px;
}


/* body {
  overflow: visible;
}

[class*="GetStartedstyle__GetStarted"] {
  overflow-y: revert;
} */

/* .fourth_content_section .bottom-img {
  top: auto;
  bottom: -450px;
} */


body.molecule-footer .fotter_section:before {
  content: "";
  background: url('/images/footer_left.png') no-repeat;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 0;
  width: 460px;
  height: 100%;
  z-index: 1;
}

body.molecule-footer .fotter_section,
.second_content_section {
  position: relative;
  z-index: 1;
}

.field-class .wrap-field {
  max-width: 100%;
}


/* 10-02-2023 */
.deleteAutoshipPopup {
  max-width: 600px;
  width: 100%;
}

/* 10-02-2023 */

@media (max-width: 1024px) {
  form.signupform.main-sign-frm {
    z-index: 9;
    position: relative;
  }
}

@media (max-width: 1365px) {
  body.molecule-footer .fotter_section:before {
    width: 300px;
  }
}

@media (max-width: 1700px) {
  .right_side_imageDesign {
    opacity: 0.3;
  }
}


/* 26-12-2022 */
.instant-testing ul {
  list-style-position: inside;
  padding-left: 25px;
}

.instant-testing ul li::marker {
  color: #de0081;
  font-size: 26px;
}

.instant-testing ul li p {
  font-size: 16px;
  margin: 0;
  line-height: 24px;
}

.instant-testing ul li {
  position: relative;
  list-style: circle;
}

.instant-testing h3 {
  background: #de0081;
  padding: 10px 15px;
  color: #fff;
  font-weight: 700;
  font-family: 'Integral CF';
}

/* .instant-testing ul li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  font-size: 16px;
} */
/* 30-12-2022 */
.order_details label {
  text-align: left;
  font-size: 18px;
  text-decoration: none;
  font-family: var(--common-font);
}

.field-class select,
form.invitation-link-main input[type="text"] {
  height: 40px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px 15px;
  border-radius: 20px;
  background: #fff;
}

.submit_button.field-class .btn-primary {
  height: 40px;
  border-radius: 20px;
  background: var(--green);
  border-color: var(--green);
  max-width: 250px;
  font-size: 18px;
}

.order_details {
  width: 100%;
}

.submit_button.field-class {
  text-align: center;
}

body .mainorder-detail-sec .container.order-detail-page .react-share__ShareButton {
  margin: 0 8px !important;
}

a.btn-primary-view:hover {
  color: #fff;
  text-decoration: none;
}

.order_details_main_dialog .container_for_load_data {
  padding: 20px;
}

table.comistion-table-data.dashboard-table-commsion.address-list.commission-table tbody tr td:last-child {
  min-width: 320px;
}

.main-invitaion table.comistion-table-data.dashboard-table-commsion.address-list.commission-table td button[aria-label="copy"] {
  padding: 0;
  width: 31px;
  height: 31px;
  min-width: 31px;
  transform: translateY(-9px);
}

.order_details_main_dialog table {
  margin-top: 0;
}

/* 05-1--2023 */
.dashboard_sign_main_dialog button.close_button {
  display: none;
}

.dashboard_sign_main_dialog h2.dialog_title_for_all {
  padding: 5px;
  min-height: 49px;
}

/* 6-1-2023 */


.referrer_popup_main_dialog table.comistion-table-data.dashboard-table-commsion.address-list.commission-table tbody tr td:last-child {
  min-width: auto;
}

.referrer_popup_main_dialog .comistion-table-data thead th {
  background: var(--site-purple);
  color: #fff;
  text-transform: uppercase;
  position: sticky;
  top: 0;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
}

.referrer_popup_main_dialog .wrap-field {
  margin: 0 auto;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 20px;
  overflow: hidden;
}

.referrer_popup_main_dialog .wrap-field input.form-control {
  background: #fff;
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.referrer_popup_main_dialog .wrap-field button.btn-primary-search,
.referrer_popup_main_dialog table.comistion-table-data.dashboard-table-commsion tbody tr td:last-child button {
  background: var(--green);
  border-radius: 20px;
  color: #fff;
}

.referrer_popup_main_dialog .comistion-table-data.dashboard-table-commsion td {
  text-transform: uppercase;
  font-size: 14px;
  word-break: break-all;
}

.referrer_popup_main_dialog table tbody tr td:last-child {
  min-width: auto;
}

.referrer_popup_main_dialog .table-responsive {
  height: 380px;
  margin-top: 20px;
}

.referrer_popup_main_dialog thead th {
  background: var(--site-purple);
}

.referrer_popup_main_dialog .date_range_filter {
  font-size: 15px;
}

.referrer_popup_main_dialog .Custom_filter {
  justify-content: center;
}

.referrer_popup {
  max-width: 700px;
  width: 100%;
}

.referrer_popup_main_dialog .table-responsive table.comistion-table-data.dashboard-table-commsion.address-list.commission-table {
  margin-top: 0;
}

.referrer_popup_main_dialog form span.error {
  display: block;
  text-align: center;
  margin-top: 10px;
}

form.signupform.main-sign-frm .verify-ref a {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  font-weight: 600;
  margin-top: 10px;
  display: block;
  text-transform: capitalize;
}

.Toal-commission.download_qr_code button {
  margin: 0 !important;
  padding-right: 10px !important;
  background: unset !important;
  border: unset !important;
  color: var(--green) !important;
  font-size: 16px;
}

.Toal-commission.download_qr_code button:hover {
  text-decoration: underline;
}

.react-datepicker__input-container input {
  max-width: 200px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 30px;
}

/* 16-02-2022 */

@media (max-width: 1199px) {
  .referrer_popup_main_dialog .table-responsive table.comistion-table-data.dashboard-table-commsion.address-list.commission-table {
    white-space: nowrap;
  }
}

@media (max-width: 767px) {
  .referrer_popup_main_dialog .table-responsive {
    height: 300px;
    overflow: auto;
  }

  .Custom_filter>div {
    text-align: center;
  }

  .order_id_filter {
    margin-bottom: 10px;
  }

  .order_details label {
    font-size: 14px;
  }

  .submit_button.field-class {
    margin-bottom: 0;
  }

  .instant-testing h3 {
    font-size: 20px;
  }

  .container.order-detail-page .main_pagination_section_class .order_pagination ul.pagination {
    margin: 10px auto 0 !important;
  }

  .orer-details-tables tbody tr:last-child td:before {
    display: none;
  }

  .orer-details-tables tbody tr:last-child td {
    padding-left: 10px !important;
  }

  .autoshipProduct_section {
    flex-wrap: wrap;
    width: 100%;
    max-width: calc(100% / 2 - 10px);
    flex: calc(100% / 2 - 10px);
  }

  .autoship-products {
    flex-wrap: wrap;
    gap: 20px !important;
    justify-content: flex-start !important;
  }

  .thn-lft .row>[class*=col-md],
  .container_autoship {
    max-width: 100%;
  }

  .table-wrap thead {
    display: none;
  }

  .thnkyou-page {
    margin: 30px auto;
  }

  span.Discount,
  span.Total,
  span.amount_paid {
    display: block;
    text-align: center;
  }

  span.Discount span,
  span.Discount strong,
  span.Discount div,
  span.Total span,
  span.Total strong,
  span.Total div,
  span.amount_paid strong,
  span.amount_paid div,
  span.amount_paid span {
    display: inline-block;
  }

}

.user-reviews::after {
  content: '';
  position: absolute;
  width: 500px;
  height: 100%;
  background-image: url('/images/right-image.png');
  top: 198px;
  right: 0;
  background-size: 100%;
  z-index: 0;
  left: auto;
  background-repeat: no-repeat;
}

.user-reviews::before {
  content: '';
  position: absolute;
  width: 418px;
  height: 100%;
  background-image: url('/left-image.png');
  top: 98px;
  left: 0;
  background-size: 100%;
  z-index: 0;
  right: auto;
  background-repeat: no-repeat;
}

.mainorder-detail-sec .container.order-detail-page .video-sec1 button {
  margin: 0;
}

.quill .ql-toolbar.ql-snow span.ql-formats {
  margin-right: 0;
}

.quill .ql-toolbar.ql-snow span.ql-formats button {
  padding: 7px 10px;
  margin: 0 5px;
  width: 20px;
}

p.personalized.nutritional {
  text-align: center;
}

p.personalized.nutritional-question {
  font-size: 16px;
}

.help-complex .personalized.nutritional-question {
  display: block;
  font-size: 14px;
  line-height: 1.5;
}

.help-complex .personalized.nutritional-question small {
  font-size: inherit;
}

.dna_info {
  text-align: center;
  margin: 20px;
  width: calc(100%/2);
}

button.choose-complex {
  background: #5cb5be;
  color: #fff;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  /* border-radius: 24px; */
  border-radius: 100px;
}

.dna_info-btn {
  text-align: center;
  margin: 23px;
}

p.personalized.nutritional.kit {
  margin-bottom: 12px;
  line-height: 24px;
}

p.personalized.nutritional.kit-bolder {
  margin-bottom: 12px;
  line-height: 24px;
  color: #000;
  text-align: center;
  font-weight: 600;
}

p.personalized.nutritional.listing_class {
  line-height: 22px;
  margin-bottom: 0;
}

.kitPopup .kitPopup_main_dialog .MuiDialogContent-root {
  padding: 0px 40px 10px;
}

.kitPopup .choose-complex span {
  font-size: 22px;
  display: block;
  text-align: center;
  line-height: 1;
}

.discount-price-amount {
  text-decoration: line-through 2px solid;
  color: red;
  font-weight: 700;
}

/* 27 dec 2023 starts */
.MuiPaper-root.kitPopup-content {
  /* max-width: 1070px; */
  max-width: 850px;
  border-radius: 30px;
}

.kitPopup-content .kitPopup-content_main_dialog .MuiTypography-root {
  background-color: transparent;
  position: absolute;
  top: 7px;
  right: 10px;
  padding: 0;
  margin: 0;
  min-height: unset;
}

.kitPopup-content .kitPopup-content_main_dialog .MuiTypography-root .MuiIconButton-root {
  position: static;
  display: none;
}

.kitPopup-content .kitPopup-content_main_dialog .MuiTypography-root .close_button svg {
  /* font-size: 24px; */
  font-size: 16px;
}

.kitPopup-content .MuiDialogContent-root {
  padding: 40px 30px !important;
  padding-bottom: 10px !important;
}

.kitPopup-content .MuiDialogContent-root .code-complex {
  display: flex;
  flex-wrap: wrap;
  gap: 0 10px;
}

.kitPopup-content .MuiDialogContent-root .code-complex p {
  /* font-size: 30px; */
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 16px;
  width: 100%;
}

.kitPopup-content .MuiDialogContent-root .code-complex .listing p {
  margin: 0;
  width: calc(100%/3);
  position: relative;
}

.kitPopup-content .MuiDialogContent-root .code-complex .listing {
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 10px 0;
}

.kitPopup-content .MuiDialogContent-root .code-complex .dna_info .choose-complex,
.cstm-dna_info .choose-complex {
  /* font-size: 26px; */
  font-size: 18px;
  line-height: 1.4;
  /* max-width: 815px; */
  max-width: 600px;
  font-family: var(--exo);
}

.kitPopup-content .MuiDialogContent-root .code-complex .dna_info-btn p:last-child {
  margin-bottom: 0;
}

.kitPopup-content .dna_info-btn {
  margin: 16px 0;
  width: 100%;
}

.navbar-nav>li p {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-top: 10px;
  line-height: 1.2;
  position: absolute;
  text-align: center;
  text-decoration: underline;
}


/* 27 dec 2023 ends */

/* 01 jan 2024 starts */
.kitPopup-content .dna_info {
  margin: 16px 0;
  width: calc(100%/2 - 5px);
}

.kitPopup-content .MuiDialogContent-root .code-complex .listing.array-data-content {
  justify-content: flex-start;
}

.listing.array-data-content {
  width: 100%;
}

.kitPopup-content .MuiDialogContent-root .code-complex .listing.array-data-content p {
  width: calc(100%/2);
  text-align: center;
}

.cstm-dna_info {
  margin: 16px 0;
  text-align: center;
  width: 100%;
}

.cstm-dna_info .choose-complex {
  width: auto;
}

.kitPopup-data.kitPopup-content .MuiDialogContent-root .code-complex p {
  text-align: left;
}

.kitPopup-data.kitPopup-content .MuiDialogContent-root .code-complex .kit-bolder,
.kitPopup-data.kitPopup-content .MuiDialogContent-root .code-complex .nutritional-question {
  text-align: center;
}

/* 01 jan 2024 ends */

/* 03 jan 2024 starts */
.manual-tooltip {
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 5px;
  color: #000;
  font-size: 12px;
  line-height: 1.2;
  display: block;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.manual-tooltip::after {
  content: "";
  height: 9px;
  width: 9px;
  background-color: #fff;
  transform: rotate(45deg) translateX(-50%);
  position: absolute;
  top: calc(100% - 1px);
  left: 50%;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.listing-txt {
  cursor: pointer;
}

.listing-txt:hover+.manual-tooltip {
  opacity: 1;
}

/* 03 jan 2024 ends */


/* 23 april 2024 starts */
.personalized.nutritional b {
  font-family: var(--common-fontmd);
  font-weight: 600;
}

/* 23 april 2024 ends */

/* media starts */

@media only screen and (max-width: 1399px) {
  /* .MuiPaper-root.kitPopup-content {
    max-width: 950px;
    border-radius: 25px;
  }

  .kitPopup-content .MuiDialogContent-root {
    padding: 50px;
    padding-bottom: 30px;
  }

  .kitPopup-content .MuiDialogContent-root .code-complex p {
    font-size: 26px;
    margin-bottom: 20px;
  }

  .kitPopup-content .MuiDialogContent-root .code-complex .listing {
    margin-bottom: 20px;
  }

  .kitPopup-content .MuiDialogContent-root .code-complex .dna_info .choose-complex {
    font-size: 24px;
    max-width: 700px;
  } */
}

@media only screen and (max-width: 1199px) {
  .MuiPaper-root.kitPopup-content {
    max-width: 750px;
  }

  /* .kitPopup-content .MuiDialogContent-root .code-complex p {
    font-size: 24px;
  }

  .kitPopup-content .MuiDialogContent-root .code-complex .dna_info .choose-complex {
    font-size: 22px;
    max-width: 650px;
  } */

  .kitPopup-content .MuiDialogContent-root .code-complex p {
    font-size: 17px;
  }

  .kitPopup-content .MuiDialogContent-root .code-complex .dna_info .choose-complex {
    font-size: 16px;
  }

  .kitPopup-content .MuiDialogContent-root .code-complex .dna_info .choose-complex {
    padding: 7px 20px;
  }

  .kitPopup-content .MuiDialogContent-root {
    padding: 40px 20px !important;
    padding-bottom: 10px !important;
  }
}

@media only screen and (max-width: 991px) {
  /* .MuiPaper-root.kitPopup-content {
    max-width: 700px;
  }

  .kitPopup-content .MuiDialogContent-root .code-complex p {
    font-size: 20px;
  }

  .kitPopup-content .MuiDialogContent-root .code-complex .dna_info .choose-complex {
    font-size: 18px;
    max-width: 600px;
  } */
}

@media only screen and (max-width: 767px) {
  .kitPopup .kitPopup_main_dialog .MuiDialogContent-root {
    padding: 0px 20px 10px;
  }

  /* .kitPopup-content .MuiDialogContent-root .code-complex p {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .kitPopup-content .MuiDialogContent-root .code-complex .dna_info .choose-complex {
    font-size: 16px;
    max-width: 500px;
  }

  .kitPopup-content .MuiDialogContent-root {
    padding: 50px 30px;
    padding-bottom: 30px;
  }

  .kitPopup-content .dna_info {
    margin: 16px 0;
  } */

  .kitPopup-content .dna_info {
    width: calc(100%);
    margin-bottom: 0;
  }

  .kitPopup-content .MuiDialogContent-root .code-complex .dna_info .choose-complex {
    width: auto;
    min-width: 330px;
    padding: 7px 18px;
  }

  .kitPopup-content .MuiDialogContent-root .code-complex p,
  .kitPopup-content .MuiDialogContent-root .code-complex .dna_info .choose-complex {
    font-size: 16px;
  }

  .kitPopup-content .MuiDialogContent-root .code-complex .listing {
    gap: 5px 0;
  }

  .kitPopup-content .MuiDialogContent-root {
    padding: 40px 15px !important;
    padding-bottom: 10px !important;
  }

  .cstm-dna_info .choose-complex {
    width: 100%;
    margin-bottom: 0;
    font-size: 16px;
  }

  .cart-product-name {
    text-align: center;
  }
}

@media (max-width:568px) {
  .mainorder-detail-sec .container.order-detail-page .video-sec1 button {
    margin: 0;
    padding: 7px 10px;
  }

  .MuiDialog-paperScrollPaper {
    margin: 0 10px;
  }

  .thnku-sec h4 br {
    display: none;
  }

  /* .kitPopup-content .MuiDialogContent-root {
    padding: 40px 15px;
    padding-bottom: 30px;
  }

  .kitPopup-content .kitPopup-content_main_dialog .MuiTypography-root {
    top: 1px;
    right: 5px;
  } */

  /* .kitPopup-content .kitPopup-content_main_dialog .MuiTypography-root .close_button svg {
    font-size: 16px;
  } */

  /* .kitPopup-content .MuiDialogContent-root .code-complex .dna_info .choose-complex {
    padding: 7px 13px;
  } */

  .kitPopup-content .MuiDialogContent-root .code-complex .dna_info .choose-complex {
    width: 100%;
    min-width: unset;
  }
}

.submit-btn-agree {
  display: flex;
  justify-content: center;
}

button.btn-primary-btn.agreemnt-sign {
  color: #fff;
  border-radius: 20px;
  background: #5cb5be;
}

.consent-share-report {
  font-size: 16px;
  line-height: 3.5rem;
}

a.login-navigate {
  color: var(--green);
  cursor: pointer;
  font-size: 14px;
}

.capsule img {
  max-width: 600px;
}

sup.trade-mark {
  font-size: 12px;
  vertical-align: middle;
  font-weight: 600;
  position: relative;
  right: 1px;
}

sup.trade-mark-new {
  font-size: 13px;
  vertical-align: super;
  font-weight: 600;
  position: relative;
}

.purchase-button {
  display: flex;
  justify-content: center;
  gap: 16px;
}

button.view-existing-order {
  background: var(--green);
  color: #fff;
  font-size: 16px;
  border-radius: 20px;
}

button.view-order-now {
  background: var(--green);
  color: #fff;
  font-size: 16px;
  border-radius: 20px;
}

p.existing-order {
  text-align: center;
}

p.latest-signup {
  width: 100%;
  max-width: 537px;
  font-size: 25px;
}

.contain-bx p>span {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contain-bx .rating-view p {
  margin: 12px 0 !important;
}

.contain-bx p {
  margin-bottom: 0 !important;
}

.contain-bx .rating-text {
  min-height: 90px;
  position: relative;
}

.review_section .rating_section_content>div:not(:first-child) p.body-data {
  position: absolute;
  top: 0;
  right: -13px;
}

/* 13 dec 2024 */

.react-datepicker__month {
  font-size: 11px;
  width: 100%;
}

.react-datepicker__day-names {
  white-space: nowrap;
  margin-bottom: -8px;
  font-size: 10px;
}

/*dec 13 2024 */

.react-datepicker__day-names {
  font-size: 12px;
}

.react-datepicker__month {
  font-size: 12px;
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
  font-size: 12px;
}

select.react-datepicker__month-select {
  font-size: 12px;
}

.react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select {
  font-size: 12px;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-around;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-around;
}



.date-picker-sign label {
  position: static !important;
  padding-left: 0 !important;
}

.date-picker-sign {
  position: relative !important;
  margin-bottom: 0 !important;
}

.date-picker-sign .react-datepicker-wrapper {
  width: 100%;
}

.date-picker-sign .react-datepicker-wrapper input {
  width: 100% !important;
  max-width: 100%;
}

.subscription-saved {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.card-confirmation {
  text-align: center;
}

button.btn-saved-subscription {
  background: var(--green);
  color: #fff;
  width: 100%;
  max-width: 100px;
  border-radius: 10px;
}

p.delivery-dates {
  text-align: center;
}

h3.delivery-dates {
  text-align: center;
  font-weight: 700;
}

.all-order-details img {
  width: 100%;
  height: 100%;
}

.graphic_calender_main_dialog .MuiDialogContent-root.container_for_load_data {
  padding: 0;
}

.new-address-edit-pop.MuiDialog-paper {
  width: 100%;
}

/* 10jan 2025 */
.address-data-section {
  display: flex;
  flex-wrap: wrap;
}

.new-address-box {
  box-shadow: 0 0 10px 0px rgb(0 0 0 / 10%);
  border-radius: 15px;
  margin: 10px !important;
  background: #fff;
  width: 100%;
  max-width: 300px;
  min-height: 250px;
}

.user-address-details h5 {
  padding: 16px;
}

.edit-remove-section {
  display: flex;
  cursor: pointer;
  position: relative;
  top: 40px;
  font-size: 16px;
  color: var(--green);
  left: 20px;
  gap: 40px;
}

.edit-remove-section .edit-address-section:not(:last-child) {
  position: relative;
}

.edit-remove-section .edit-address-section:not(:last-child):after {
  content: "";
  height: 17px;
  width: 2px;
  background: #000;
  position: absolute;
  top: 2px;
  left: 45px;
}

.address-box {
  padding: 62px;
  box-shadow: 0 0 10px 0px rgb(0 0 0 / 10%);
  border-radius: 15px;
  margin: 10px !important;
  background: #fff;
  width: 100%;
  display: block;
  max-width: 300px;
  font-size: 50px;
  color: grey;
  cursor: pointer;
}

.plus-icon-address {
  text-align: center;
}

.address-box p {
  color: #000;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
}

h5.title-address {
  font-family: 'SFPromd';
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  line-height: 19px;
}

.mb-3.field-class.review-checkout {
  width: 100%;
  max-width: 440px;
}

.mb-3.field-class.review-checkout select.form-control {
  background: var(--green);
  color: #fff;
}

.form-wrappper.review input.form-control {
  width: 100%;
  max-width: 436px;
  border-radius: 10px;
}

.order_section_btn button.ml-2 {
  width: 100%;
  font-size: 18px !important;
  max-width: 155px;
}

.button_section button {
  font-size: 18px !important;
}

/* 5 march 2025 edit susb css */

.autoship-action .main-edit-sec {
  display: flex;
  gap: 5px;
}

.main-edit-sec img {
  height: 25px !important;
  cursor: pointer;
}

.edit-text-tag {
  font-size: 18px;
  color: #009245;
  font-family: 'SFPro-Regular';
}

.skip-text-tag {
  font-size: 18px;
  color: #0071bc;
  font-family: 'SFPro-Regular';
}

.main-edit-sec .skip svg {
  color: #0071bc !important;
  font-size: 28px !important;
  position: relative;
  bottom: 3px;
}

.main-edit-sec .delete svg {
  color: #0071bc !important;
  font-size: 28px !important;
  position: relative;
  bottom: 3px;
}

.delete-text-tag {
  font-size: 18px;
  color: #ff0000;
  font-family: 'SFPro-Regular';
}

.mainorder-detail-sec .container.order-detail-page .autoship-action button svg {
  width: 33px;
  height: 34px;
  margin: -8px 0 0;
  fill: #eba72a;
}


.support-main-cstm-email {
  color: white !important;
}